import React, { useState, useEffect } from 'react'
import styles from './login-popup.module.scss'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { Button, Form, Toast } from 'antd-mobile'
import NumberInput from '@/components/number-input/number-input'
import { isPhone, Storage } from '@bihu/common-js'
import activityApi from '@/apis/activity'
import { TOKEN, ENTER_AGREEMENT, CURRENT_SKIN_INDEX } from '@/constants/storage'

interface Props {
  callBack: () => void
  type?: 'getQuota', // 领取额度
}

const DetainmentPopup: React.FC<Props> = props => {
  const { callBack, type } = props
  const navigate = useNavigate()

  // 关闭弹窗
  const close = () => {
    // navigate(-1)
    callBack()
  }

  const [form] = Form.useForm()
  // 手机号码
  const [phone, setPhone] = useState('')
  // 验证码
  const [code, setCode] = useState('')

  // 检验手机号码
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkMobile = (_: any, value: string) => {

    if (phone) {

      if (!isPhone(phone)) {
        return Promise.reject(new Error('手机号码格式有误'))
      }
      return Promise.resolve()
    }
    return Promise.reject(new Error('请输入手机号码'))
  }

  // 检验验证码
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkCode = (_: any, value: string) => {

    if (code) {
      // eslint-disable-next-line no-magic-numbers
      if (code.length !== 6) {
        return Promise.reject(new Error('验证码长度为6'))
      }
      return Promise.resolve()
    }
    return Promise.reject(new Error('请输入验证码'))
  }

  // 定时器对象
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>()
  // 当前倒计时剩余时间
  const [time, setTime] = useState(0)

  // 倒计时时间
  const countDownTime = 60
  // 倒计时函数
  const countDownFn = () => {
    setTime(countDownTime)
  }

  useEffect(() => {
    if (time > 0) {
      let t = setTimeout(() => {
        setTime(time - 1)
      }, 1000)
      setTimer(t)
    } else {
      timer && clearTimeout(timer)
      setTimer(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  // 获取验证码
  const getCode = async() => {
    console.log('有没有触发', phone)
    if (!phone) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(phone)) {
      return Toast.show('手机号码格式有误')
    }
    countDownFn()

    try {
      await activityApi.smsSend({
        mobile: +phone
      })
    } catch (error) {
      timer && clearTimeout(timer)
    }

    Toast.show('获取验证码成功')
  }

  const [adChannelCode, setAdChannelCode] = useState('')

  // 立即注册
  const register:any = async() => {
    // console.log('手机号', phone, code)
    if (!phone) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(phone)) {
      return Toast.show('手机号码格式有误')
    }
    if (!code) {
      return Toast.show('请输入验证码')
    }
    try {
      Toast.show({
        icon: 'loading',
        content: '领取中…',
        duration: 0,
      })
      const abTestType = 5
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = await activityApi.login({
        mobile: phone,
        code,
        adChannelCode,
        abTestType,
        isFormReq: (type === 'getQuota' && adChannelCode !== 'YxNj9v'),
      })
      Toast.clear()
      // console.log(res)
      Storage.set(TOKEN, res.token)
      // console.log('===>', type, adChannelCode)
      // 判断是否为360渠道链接
      judgeLoginAfterPage()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error:any) {
      // eslint-disable-next-line no-console
      console.log(error)

      // Toast.clear()
      // Toast.show(error.msg)
    }

    // form.setFields([
    //   {
    //     name: 'phone',
    //     errors: ['手机号已注册']
    //   }
    // ])
  }

  const judgeLoginAfterPage = () => {
    if (type === 'getQuota' && adChannelCode !== 'YxNj9v') {
      window.location.href = `/fill-information?adChannelCode=${adChannelCode}`
    } else {
      window.location.href = `/fill-information/success?options=${encodeURIComponent(JSON.stringify({ isNotFormReq: true }))}`
    }
  }

  return <section className={styles.modalPage}>
    <div className={styles.main}>
      <div className={styles.content}>
        <img className={styles.img} src={require('@imgs/information/wait-red.png')} alt="身份标识" />
        <div className={styles.title}>仅需一分钟为您匹配借款产品</div>
        <div className={styles.title}>确定要离开吗</div>
        <div className={styles.formBox}>
          {/* <div className={styles.mask}> */}
          <Form form={form} initialValues={{ code: '' }}>
            <Form.Item
              name="phone"
              label=""
              rules={[
                {
                  required: true,
                  validateTrigger: 'onBlur',
                  validator: checkMobile,
                }
              ]}
            >
              <div className={styles.inputBox}>
                <NumberInput
                  placeholder="请输入手机号"
                  value={phone}
                  numberFormat={{
                    decimal: false,
                    negative: false,
                    leadingZero: false,
                    maxLength: 11
                  }}
                  onChange={val => {
                    setPhone(val)
                  }}
                />
              </div>
            </Form.Item>
            <Form.Item name="code" label="" rules={[
              {
                required: true,
                validateTrigger: 'onBlur',
                validator: checkCode,
              }
            ]}>
              <div className={styles.inputBox}>
                <NumberInput
                  placeholder="请输入验证码"
                  value={code}
                  numberFormat={{
                    decimal: false,
                    negative: false,
                    maxLength: 6
                  }}
                  onChange={val => {
                    setCode(val)
                  }}
                />
                {
                  time ? <span className={classNames(styles.code, styles.codeText)}>剩余 {time}s</span>
                    : <span className={styles.code} onClick={getCode}>获取验证码</span>
                }

              </div>
            </Form.Item>
          </Form>
          {/* </div> */}
        </div>
        <Button block className={styles.btn} shape="rounded" color="primary" onClick={register}>
          继续申请
        </Button>
      </div>
      <div className={styles.close} onClick={close}></div>
    </div>
  </section>
}

export default DetainmentPopup