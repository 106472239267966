// 失效页面
import Icon from '@/components/icon/icon'
import React from 'react'
import styles from './failure.module.scss'

interface Props {}

const Failure: React.FC<Props> = () => {
  return (
    <div className={styles.failure}>
      <Icon type="fail-warn" className={styles.failWarnIcon} />
      <div className={styles.text}>链接已失败，无法打开</div>
    </div>
  )
}

export default Failure
