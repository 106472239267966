// 注册协议
import React from 'react'
import styles from './register.module.scss'
// import Header from '@/components/header/header'

interface Props {}

const Register: React.FC<Props> = () => {
  return (
    <div className={styles.register}>
      {/* <Header title="注册协议" /> */}
      <div>更新日期：2022年12月1日</div>
      <div>生效时间：2022年12月1日</div>
      <div>版本：2022年11月版</div>
      <div>“元信花”提供各项在线便捷服务的所有权和经营权归海南柠坤科技有限公司所有。以下均用“元信花”代表。</div>
      <div>特别提示：</div>
      <div>
        在使用元信花在线便捷服务之前，您应当认真阅读并遵守《元信花用户服务协议》（以下简称“本协议”），请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、争议解决和法律适用条款。免除或者限制责任的条款可能将以加粗字体显示，您应重点阅读。如您对协议有任何疑问的，应向元信花客服咨询。
      </div>
      <div>“元信花”提供找工、找项目、找人及其他与此相关联的在线便捷服务。</div>
      <div>
        您通过页面点击的方式即已确认您同意本协议，成为“元信花”用户，并接受本协议的全部注册条款以及元信花《隐私政策》和本平台内公布的其他专项协议或规则，包括但不限于元信花《好活信息发布规则》《找活信息发布规则》《增值服务协议》（以下简称“本平台规则”）的所有条款的约束。您在使用“元信花”产品之时本协议即生效。“元信花”保留更新本协议的权利，我们会在每次更新本协议时再次通知您。如果您不同意本协议或后续“元信花”对本协议的任何更改，您有权停止使用相关服务。
      </div>
      <div>用户服务基本条款</div>
      <div>用户定义：</div>
      <div>本协议的用户包含个人、班组、施工队、突击队，以下统称为该平台的“用户”。</div>
      <div>
        用户同意使用“元信花”仅用于找好活、找项目、找人等合法的目的。已注册用户可以把个人信息通过“元信花”报名给发布的企业。在此种情况下，用户个人信息仍然储存在元信花数据库中，但元信花不承诺用户个人信息长期储存在“元信花”数据库中。</div>
      <div>
        若用户提供任何错误、不实或不完整的资料，或“元信花”有理由怀疑资料为错误、不实或不完整及违反用户注册条款的，元信花有权修改用户的注册昵称、个人资料、发布的信息等，或暂停或终止向该用户提供元信花提供的全部或部分服务。
      </div>
      <div>
        未经用户的许可，“元信花”不会把用户的个人信息转发给任何未经注册的企业，但是用户须同意“元信花”可以使用其个人资料。“元信花”仅为用户提供就业机会，不参与找活或找好活等实际内容；对于任何公司（无论是否在中国境内）与用户之间产生的任何纠纷，均与元信花无关。因此，您通过元信花发布的信息联系对方时，请务必核实对方信息，谨防诈骗。
      </div>
      <div>一、服务内容</div>
      <div>
        1.1 在开通服务的城市，“元信花”将信息展示在页面区域中。用户可根据实际情况选择适合自己的好活并进行自主联系。
      </div>
      <div>
        1.2 网络服务的具体内容由“元信花”根据实际情况提供。“元信花”保留随时变更、中断或终止部分或全部网络服务的权利。“元信花”行使修改或中断服务的权利，不须对用户或第三方承担任何责任。
      </div>
      <div>
        1.3 “元信花”在提供网络服务时，可能会对部分网络服务的用户收取一定的费用。在此情况下，“元信花”会在相关页面上做明确的提示。用户有选择是否付费的权利。如用户拒绝支付该费用，则不能使用相关的网络服务。
      </div>
      <div>
        1.3.1 免费服务的使用可能需要满足一定的条件，具体条件以“元信花”公布的条件为准。对于免费服务，“元信花”有权根据实际情况，对免费服务的具体服务内容、服务期限及服务标准等进行单方变更，用户对此无异议，并接受前述的变更。“元信花”无需为此向用户承担任何责任。
      </div>
      <div>
        1.3.2 “元信花”所提供的认证服务、找人服务套餐为收费服务。费用具体说明以元信花APP相应页面为准。若用户使用本服务中的收费服务，用户应按有关的收费标准和付款方式支付相关费用。
      </div>
      <div>
        1.3.3 “元信花”可能根据实际需要对收费服务的收费标准、方式等进行修改和变更，“元信花”也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，“元信花”将在相应服务页面进行通知或公告，具体以《增值服务协议》为准。
      </div>
      <div>1.4 关于⾯试聊天等即时通讯服务</div>
      <div>
        1.4.1 用户在接受元信花提供与元信花注册用户或元信花关联方用户进行在线开聊、邀约在线视频等即时通讯服务时，应当遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚，并保证所传输的信息真实性等七条底线。
      </div>
      <div>
        1.4.2 用户通过本平台与他人在线开聊、拨打电话以及在线视频等商务场景下产生的文字、语音及视频等形式的沟通信息，元信花将会根据法律规定暂时存储，且仅用于投诉举报的处理、安全风控及离线暂存功能的实现。
      </div>
      <div>
        1.4.3 元信花对该信息的采集、传输及存储均会采取加密、防泄露等相关措施，基于用户找活安全考虑，通常我们会存储3年。
      </div>
      <div>
        1.4.4 为保护其他用户隐私，您不得下载、传播或公开发布本条规定的其他用户通讯信息，如在线视频、聊天记录等。如因此造成元信花损失，或者侵害其他用户权益的，您应当承担违约责任或赔偿责任。
      </div>
      <div>二、双方权利与义务</div>
      <div>
        2.1 用户在申请使用“元信花”网络服务时，须向“元信花”提供准确真实的个人资料。在平台上需要发布好活信息的用户须保证发布的用人信息真实，且具有相关资质证明。平台注册用户须对提供的个人资料承担一切责任，如个人资料有任何变动，须及时更新。
      </div>
      <div>
        2.2 用户通过唯一手机号获取验证码，并在元信花登录后，该账号由用户负责妥善保管，用户应当对以其用户账号进行的所有活动和事件负法律责任。
      </div>
      <div>
        2.3 用户须同意接受“元信花”拥有通过邮件、短信电话等形式，向在本站注册、发布、找活联络人发送找活或其他告知信息的权利。
      </div>
      <div>2.4 用户须同意元信花可以使用用户个人资料，元信花承诺不会将用户个人资料用于犯罪等违法行为。</div>
      <div>用户在使用“元信花”网络服务过程中，必须遵循以下原则：</div>
      <div>2.4.1 遵守中华人民共和国现行有关的法律和监管规定；</div>
      <div>2.4.2 不得为任何违法目的的行为而使用“元信花”网络服务系统；</div>
      <div>2.4.3 遵守所有与国家网络监管部门规定的有关的网络协议、规定和程序；</div>
      <div>2.4.4 不得利用“元信花”网络服务系统进行任何可能对互联网的正常运转造成不利影响的行为；</div>
      <div>2.4.5 不得利用“元信花”网络服务系统传输任何违反国家法律法规禁止性规定的；政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或教唆犯罪的；煽动民族仇恨、民族歧视，破坏民族团结的；欺诈、虚假、不准确或存在误导性的；侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；违背当地风俗习惯的；其他违背社会公共利益或者公共道德或依据相关“元信花”相关协议/规则/政策的规定不适合在“元信花”中发布的信息资料；</div>
      <div>2.4.6 不得利用“元信花”网络服务系统进行任何不利于“元信花”的行为；</div>
      <div>2.4.7 如发现任何非法使用用户账号或账号出现安全漏洞的情况，用户应立即通知“元信花”。</div>
      <div>2.4.8 用户须同意使用元信花仅用于合法的目的，如找活、记工等。已注册用户可以把个人信息通过本应用软件报名给发布的企业。在此种情况下，用户个人信息仍然储存在元信花数据库中，但元信花不承诺用户个人信息长期储存在元信花数据库中。元信花保留修改用户个人找活信息不适信息的权利。</div>
      <div>2.5 用户通过本平台所发生的一切债权债务关系，劳务、民事等各类法律纠纷，均由用户本人承担，本平台概不负责，亦不负任何法律责任。</div>
      <div>2.6 元信花有权对用户个人不适当的信息进行适当修改。</div>
      <div>2.7 未经用户的许可，元信花不会将找活人员的个人信息转发给任何未经注册的企业。</div>
      <div>2.8 元信花仅提供平台服务，对于任何公司及个人（无论是否在中国境内）与用户之间的任何纠纷，概不负责。因此，请用户通过元信花联系对方时，应注意保护自己的人身、财产安全，谨防诈骗。</div>
      <div>2.9 在不透露单个用户隐私资料的前提下，“元信花”有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</div>
      <div>2.10 不得恶意注册、使用元信花账号。用户不得实施恶意注册多个账号，使用元信花账号，利用多个账号同时发布信息，造成刷屏等。</div>
      <div>2.11 访问异常检测与保护。如果用户账户存在一定期间内的异常访问、大量发布，大量投诉等异常信息，存在安全隐患的，元信花将对该用户启动暂时冻结等操作（包括但不限于无法查看其他用户发布的信息无、法发布信息等）的保护，直至账号的使用情况恢复。</div>
      <div>2.12 可能因软件BUG、版本更新缺陷、第三方病毒攻击或其他任何因素导致您的好活信息、积分、积分记录等账号数据发生异常。在数据异常的原因未得到查明前，元信花有权暂时冻结该账号；若查明数据异常为非正常行为所致，元信花有权恢复数据至异常发生前的原始状态，而无须向您承担任何责任。</div>
      <div>2.13 基于⻛控策略/安全⻛险/产品政策等的需要，元信花可能要求部分⽤户补充提供材料（包括但不限于身份认证信息、企业资质证明、承诺书、业务协议等），具体要求会在相关⻚⾯上做明确展示。如⽤户拒绝提供前述材料，元信花有权视情况暂停或终⽌向该⽤户提供部分或全部服务。</div>
      <div>2.14 元信花的积分等增值服务只在产品平台上或由元信花直接对外出售或者赠送使⽤权，⽤户不得在任何其他平台或渠道购买元信花的虚拟产品或服务。对于⽤户使⽤⾮官⽅提供或者售卖的积分等虚拟产品或服务造成的任何损失，元信花不对其负责；因在其他平台充值或找他⼈代充等⽅式购买产品或服务导致元信花遭受损失的，⽤户应当承担违约责任并赔偿损失。</div>
      <div>2.15 ⽤户应通过本平台使⽤相关服务，未经许可，不得通过其他第三⽅⼯具或运营平台获取元信花服务，包括但不限于通过第三⽅软件登录元信花账号、发布信息、查看信息等。如因⽤户使⽤第三⽅软件导致相关信息泄漏的，元信花不承担任何责任，且⽤户还应承担由此给元信花造成的损失。</div>
      <div>2.16 两位⽤户名下认证的账号，经系统判定为关联账号的，如其中⼀个账号因违法违规被冻结的，其他关联账号均将被同时冻结。</div>
      <div>2.17 ⽤户有下列⾏为的，元信花在发现或接到投诉后，有权采取冻结账号、限制功能、升级认证或以其他⽅式暂停向该⽤户提供服务，并要求⽤户承担相应的损害赔偿责任：</div>
      <div>1）在本平台以外的任何第三⽅平台或渠道（包括但不限于淘宝、闲⻥等）售卖积分等虚拟产品或服务的⾏为；</div>
      <div>2）通过第三⽅平台或渠道（包括但不限于淘宝、闲⻥等）购买积分等虚拟产品或服务的行为；</div>
      <div>3）为任何注册⽤户或⾮注册⽤户提供⾃动登录到本平台、代办或协助他⼈代办身份认证服务的或代售身份认证所需的相关材料或凭据等；</div>
      <div>4）利⽤本平台可能存在的漏洞恶意充值积分等虚拟产品或服务；</div>
      <div>5）通过邀请虚假用户注册平台，违规获取平台积分等虚拟产品或服务;</div>
      <div>6）以任何⽬的⾃⾏或授权、允许、协助任何第三⼈对平台内的任何信息内容进⾏⾮法获取，⽤于商业⽤途或其他任何⽬的。“⾮法获取”是指采⽤包括但不限于“蜘蛛”(spider)程序、爬⾍程序、拟⼈程序等⾮真实⽤户或避开、破坏技术措施等⾮正常浏览的⼿段、⽅式，读取、查看、复制、转存、获得数据和信息内容的⾏为。</div>
      <div>三、版权声明</div>
      <div>3.1 “元信花”提供的网络服务内容包括：文字、软件、声音、图片、录像、图表等。所有这些内容版权归海南柠坤科技有限公司。</div>
      <div>3.2 用户只有在获得“元信花”或其他相关权利人的授权之后才能使用“元信花”提供的网络服务，不能擅自复制、再造“元信花”提供的网络服务、或创造与服务内容有关的派生产品。</div>
      <div>3.3 如用户需转载“元信花”的文章、图片及其他内容，必须征得元信花或相关权利人的书面特别授权，注明作者及出处“元信花”；凡作者或权利人声明不得转载的文章、图片或其他内容，任何单位及个人不得转载。若违反上述规定，“元信花”保留追究当事人法律责任的权利。</div>
      <div>3.4 未经“元信花”授权，任何人不得建立本应用软件的镜像。</div>
      <div>四、信息的发布</div>
      <div>4.1 任何用户不得使用“元信花”发送或储存任何违反适用法律或法规的资料；</div>
      <div>4.2 任何用户不得以任何形式侵犯他人的版权、商标权、商业秘密或其他知识产权；</div>
      <div>4.3 任何用户不得侵犯个人和社会大众的隐私安全；禁止传输任何非法的、骚扰性的、中伤性的、辱骂性的、恐吓性的、伤害性、庸俗或淫秽的信息。</div>
      <div>4.4 任何用户不得发布任何与本软件找活找人目的不适之信息；</div>
      <div>4.5 任何用户不得发布任何不完整、不准确、虚假的或具有误导性的信息；</div>
      <div>4.6 任何用户对所发布的信息承担完全责任。</div>
      <div>五、信息发布规范</div>
      <div>禁止发布包含以下内容的信息，⽤户有发布/散布/传播如下相关信息的，元信花在发现或接到投诉后，有权采取冻结账号、限制功能、升级认证或以其他⽅式暂停向该⽤户提供服务，并要求⽤户承担相应的损害赔偿责任：</div>
      <div>5.1 法律禁止的内容。用户不得发布、传播法律法规禁止的内容。</div>
      <div>5.1.1 违反或反对宪法确定的基本原则、社会主义制度；</div>
      <div>5.1.2 危害国家安全，泄露国家秘密，颠覆国家政权、破坏国家统一、主权和领土完整的；</div>
      <div>5.1.3 损害国家荣誉和利益的；</div>
      <div>5.1.4 煽动民族仇恨、民族歧视，破坏民族团结的；</div>
      <div>5.1.5 破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
      <div>5.1.6 散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
      <div>5.1.7 散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</div>
      <div>5.1.8 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</div>
      <div>5.1.9 以非法民间组织名义活动的；</div>
      <div>5.1.10 含有法律、行政法规禁止的其他内容</div>
      <div>5.2 项目地址找人信息地址严重不符合的；</div>
      <div> 5.3 可能对其他用户进行人身攻击或负面影响的；</div>
      <div>5.4 信息内容与实际情况完全不符合的情况；</div>
      <div>5.5 其他以骗取钱财为目的发布的欺诈信息，存在额外收费的，包括但不限于押金、办卡费用、办证费用、培训费、居间费、介绍费等；</div>
      <div>5.6 除了平台工种以外的信息；</div>
      <div>5.7 赌博类信息；</div>
      <div>5.8 侵犯其他人合法权益的信息；</div>
      <div>5.9 欺诈类信息；</div>
      <div>5.9.1 冒用身份的欺诈信息：使用他人特征信息，误导其他用户的；</div>
      <div>5.9.2 以获取他人信息和证件为目的发布的欺诈信息；</div>
      <div>5.10 违法办证、代办身份证、技能证、护照、健康证等证件等内容；</div>
      <div>5.11 不能保证应聘方人身安全的信息；</div>
      <div>5.12 其他违反国家法律、行政法规、部门规章、地方性法规等规范性文件的内容；</div>
      <div>六、信息的使用</div>
      <div>6.1 任何用户仅可就找活目的使找人者找人信息，不得用于任何其他用途，任何用户必须保证不用于下列任何一种用途：</div>
      <div>(a) 供“元信花”的竞争同行用此方法寻求找人单位的业务联络或进行与“元信花”相竞争的其他活动。</div>
      <div>(b) 擅自删除或修改任何其他人或“元信花”公布的资料。</div>
      <div>(c) 擅自将取自“元信花”的资料转给第三方使用或向第三方透露。</div>
      <div>6.2 “元信花”提供的其它信息，仅应限于与其相应内容有关的目的而被使用；任何用户不得将任何本软件的信息用作任何商业目的。</div>
      <div>七、信息的公开</div>
      <div>7.1 “元信花”不公开任何用户不愿意公开的信息，除非：</div>
      <div>(a) 用户授权“元信花”透露这些信息；</div>
      <div>(b) 相应的法律要求及政府部门要求“元信花”提供用户的资料；</div>
      <div>(c) 该信息系用户已发布的信息；</div>
      <div>(d) 该信息系为已进入公共领域的信息。</div>
      <div>7.2 “元信花”绝对尊重用户，不擅自编辑或修改用户的登记内容，除非有法律要求及公安管理规定；</div>
      <div>7.3 “元信花”保留判定用户的行为是否符合本协议的权利，如果“元信花”认为用户违背了本协议，有权采取冻结账号、限制功能、升级认证或其他“元信花”认为必要的⽅式暂停向该⽤户提供服务，直至用户停止违反本协议的行为。</div>
      <div>7.4 所有发给用户的通告都可通过用户在“元信花”登记注册的电子邮件或软件公告传送，且在传送后壹日内视为送达。服务条款的修改、服务变更、或其他重要事项都会以此形式进行。</div>
      <div>八、信息的安全性</div>
      <div>8.1 用户不得破坏或企图破坏“元信花”或软件的安全规则，其中包括但不限于:</div>
      <div>8.1.1 接触未经许可的数据或进入未经许可的服务器或账户、邮箱或许可第三方接入未经本网站同意的由本网站发布、输送、传播的数据、找活或其他信息等；</div>
      <div>8.1.2 没有得到许可，企图探查，扫描或测试系统或网络的弱点，破坏或者企图破坏网络安全保护措施，对网络服务及相关软硬件设施进行破解、破坏、删除、修改或者增加的；</div>
      <div>8.1.3 对计算机信息网络中存储或者传输的数据和应用程序进行删除、修改或者增加的，包括但不限于：通过超载，"邮件炸弹"或"摧毁"等手段，企图干涉“元信花”对用户及网络的服务；</div>
      <div>8.1.4 发送促销、产品广告及其他服务的E-mail；</div>
      <div>8.1.5 故意制作、传播计算机病毒等破坏性程序的；</div>
      <div>8.1.6 伪造TCP/IP数据包名称或部分名称；</div>
      <div>8.1.7 其他危害计算机信息网络安全的活动。对于破坏系统或网络可能导致犯罪的行为,“元信花”将调查、干预此类破坏行为的发生，并将与执法部门合作，起诉此类破坏行为的使用者。</div>
      <div>九、风险分担</div>
      <div>9.1 用户理解并同意，“元信花”在本合同项下的义务是为用户提供好活信息且不对信息的真实性负责。用户应自行甄别相关信息的真实性与对应性，“元信花”不对用户使用本系统报名入职后与对方产生的纠纷负责。</div>
      <div>9.2 如因用户与他人的纠纷，导致“元信花”被追诉或受到损失，则“元信花”最终承担的责任与损失均由用户承担，“元信花”为维护自身权益而产生的费用（包括但不限于律师费、诉讼费、保全费、调查取证等费用）亦由用户承担。</div>
      <div>9.3 由于Internet发展的现状，可能会出现线路速度降低、因网络调整造成短时的线路中断等情况，用户认同上述情况属正常现象。但中断时间以每月累计不超过（含）4小时为限（不可抗力除外）。</div>
      <div>十、责任</div>
      <div>10.1 元信花”会随时关注系统的运行，并保留对用户发布违规消息、试图破坏软件系统等违规行为实时进行处置的权利。</div>
      <div>10.2 “元信花”不承诺软件能够长期无错误运营；不保证其服务器免受病毒或其他机械故障的侵扰。如果任何用户在使用“元信花”软件时，因第三方原因造成数据丢失等情况，“元信花”不承担任何责任。</div>
      <div>10.3 “元信花”因正常的系统维护、系统升级，或者因网络拥塞而导致软件不能正常访问的，“元信花”不承担任何责任。</div>
      <div>10.4 在任何情况下，“元信花”及鱼泡公司负责人、主管、雇员和代理商拒绝对由于用户使用本软件及其内容或不能使用本软件而造成的一切损失提供任何担保。</div>
      <div>10.5 除非法律另有规定，“元信花”在本合同项下承担的责任（不论是违约还是侵权），仅以“元信花”向该用户所收取的服务费为限，并且“元信花”不承担任何用户的任何期待利益损失或其他间接损失。</div>
      <div>10.6 元信花提供的服务中可能包括广告，用户同意在使用过程中显示元信花和第三方供应商、合作伙伴提供的广告，广告内容均由内容提供者负责。用户可通过广告了解相关产品或服务，但须自行对依该广告信息进行的交易负责，对用户因该广告信息进行的交易或广告商提供的内容而遭受的损失或损害，元信花不承担任何责任。</div>
      <div>10.7 对于⽤户上传的照⽚、资料、证件、视频、内容及图⽚等，元信花已采⽤相关措施并已尽合理努⼒进⾏审核，但不保证其内容的正确性、合法性或可靠性，相关责任由上传上述内容的⽤户承担。</div>
      <div>10.8 对于⽤户的投诉，元信花将尽合理努⼒进⾏核实和处理，但不保证⼀定能满⾜投诉者的要求。元信花有权决定是否向公众或向被投诉者公开投诉内容。对于投诉内容侵犯⽤户隐私权、名誉权等合法权益的，所有法律责任由投诉者承担，与元信花⽆关。</div>
      <div>十一、合作事项</div>
      <div>11.1 “元信花”可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与“元信花”同等的保护用户隐私的责任，则用户同意“元信花”将注册资料等提供给该第三方。</div>
      <div>11.2 在不透露单个用户隐私资料的前提下，“元信花”有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</div>
      <div>十二、免责声明</div>
      <div>12.1 用户明确同意其使用“元信花”网络服务所存在的风险将完全由自己承担；因其使用“元信花”网络服务而产生的一切后果也由自己承担，“元信花”对用户不承担任何责任。</div>
      <div>12.2 “元信花”将竭尽全力保证网络服务的及时性、安全性、准确性，但不能保证网络服务一定能满足用户的要求，也不保证网络服务不会中断。</div>
      <div>12.3 除非另有明确书面说明，在符合法律法规规定的情况下，“元信花”不对其网站和客户端上的信息、内容、材料、产品或服务做任何形式的明示或默示的声明或担保。</div>
      <div>12.4 为向用户提供便利而设置的外部链接网址，“元信花”并不保证其准确性、安全性和完整性，亦并不代表“元信花”对其链接内容的认可，用户需谨慎确认后再使用。由于与“元信花”链接的其他网站或用户所使用的网络运营商所造成的个人信息泄露或者其他任何损失，以及由此而导致的任何法律争议和后果均有其他网站或用户所使用的网络运营商负责。</div>
      <div>十三、服务变更、中断或终止</div>
      <div>13.1 如因系统维护或升级的需要而需暂停网络服务，“元信花”将尽可能事先进行通告。</div>
      <div>13.2 如发生下列任何一种情形，“元信花”有权随时中断或终止向用户提供本协议项下的网络服务而无需通知用户：</div>
      <div>13.2.1 用户提供的个人资料不真实；</div>
      <div>13.2.2 用户违反本协议中规定的使用规则。</div>
      <div>13.3 除前款所述情形外，“元信花”同时保留在不事先通知用户的情况下随时中断或终止部分或全部网络服务的权利，对于所有服务的中断或终止而造成的任何损失，“元信花”无需对用户或任何第三方承担任何责任。</div>
      <div>十四、违约赔偿</div>
      <div>用户同意保障和维护“元信花”及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给“元信花”或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。</div>
      <div>十五、修改协议</div>
      <div>15.1 “元信花”将可能不时地修改本协议的有关条款，一旦条款内容发生变动，“元信花”将会在相关的页面提示修改内容。</div>
      <div>15.2 如果不同意“元信花”对服务条款所做的修改，用户有权停止使用网络服务。如果用户继续使用网络服务，则视为用户接受服务条款的变动。</div>
      <div>十六、法律管辖</div>
      <div>16.1 本协议的订立、执行和解释及争议的解决均应适用中国法律。</div>
      <div>16.2 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，您在此同意将该争议提交至成都仲裁委员会，其仲裁裁決是终局的。</div>
      <div>十七、通知和送达</div>
      <div>本协议项下所有的通知均可通过重要页面公告、电子邮件或常规的信件传送等方式进行；该通知于发送之日视为已送达收件人。</div>
      <div>十八、其他规定</div>
      <div>18.1 本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。</div>
      <div>18.2 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并旦有约束力</div>
    </div>
  )
}

export default Register
