/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useMemo, useRef } from 'react'
import styles from './joint-auth-login.module.scss'
import Header from '@/components/header/header'
import classNames from 'classnames'
import ProductList from '@/components/product-list/product-list'
import productApi, { ProductListResponse, ProductListParams } from '@/apis/product'
import { ENTRANCE_TYPE } from '@/constants/common'
import { isIOS, isInWeChat, isMobile, download } from '@bihu/common-js'
import activityApi from '@/apis/activity'
import { useSearchParams } from 'react-router-dom'
import { Toast, Button } from 'antd-mobile'
import copy from 'copy-to-clipboard'
import indexApi from '@/apis'

interface Props{}

const Success:React.FC<Props> = () => {
  const [search] = useSearchParams()
  const options:any = search.get('options')
  let resData:any = {}
  try {
    resData = JSON.parse(decodeURIComponent(options))
  } catch {
    resData = JSON.parse(options)
  }

  // 切换选中协议
  const checkedTaggle = () => {
    setChecked(!checked)
  }

  // 选择协议
  const [checked, setChecked] = useState(false)

  // 跳转页面
  const openOtherView = (e:any, url: string) => {
    e.stopPropagation()
    window.location.href = url
  }

  // 按钮loading
  const [btnLoading, setBtnLoading] = useState(false)
  const handleAuthorize = async() => {
    if (!checked) {
      Toast.show('请勾选下方用户服务和授权协议')
      return
    }
    let protocolIds = resData.h5Protocols.map((item:any) => {
      return item.id
    })
    setBtnLoading(true)
    const res:any = await indexApi.authH5Product({
      productId: resData.productId,
      protocolIds: `${protocolIds}${''}`,
      agree: checked,
    })
    setBtnLoading(false)
    window.location.href = res
  }

  return (
    <div className={styles.fullPage}>
      <Header title="数据授权" />
      <div className={styles.authorizeInfo}>
        <div className={styles.authorizeCompany}>
          <div className={styles.authorizeCompanyItem}>
            <img className={styles.authorizeCompanyLogo} src={require('@imgs/youqianqianbao-logo.png')} alt="" />
            <div className={styles.authorizeCompanyName} >云享钱包</div>
          </div>
          <img className={styles.interconnection} src={require('@imgs/information/interconnection.png')} />
          <div className={styles.authorizeCompanyItem}>
            <img className={styles.authorizeCompanyLogo} src={resData.productLogo} alt="" />
            <div className={styles.authorizeCompanyName} >{ resData.productName }</div>
          </div>
        </div>
        <div className={styles.authorizeRemark}>云享钱包将协助第三方平台向您提供服务，您同意云享钱包将您的以下信息传递给第三方。</div>
        <div className={styles.authorizeInfoItem}>
          <div className={styles.authorizeInfoItemSort}></div>
          <div className={styles.authorizeInfoItemText}>获取您的手机号</div>
        </div>
        <div className={styles.agreementBox} onClick={checkedTaggle}>
          <div className={styles.checked}>
            {
              checked ? <>
                <img src={require('@imgs/information/select-active.png')} />
              </> : <><img src={require('@imgs/information/select.png')} /></>
            }
          </div>
          <div>点击确认即代表同意
            {
              resData.h5Protocols && resData.h5Protocols.map((item:any) => {
                return (
                  <span
                    className={styles.agreementItem}
                    onClick={e => {
                      openOtherView(e, `/info-audit-agreement?isShowHeader=true&agreementId=${item.id}&title=${item.name}&productId=${resData.id}`)
                    }}
                    key={item.id}
                  >
                    { `《${item.name}》` }
                  </span>
                )
              })
            }
          </div>
        </div>
      </div>
      <Button block className={styles.btn} shape="rounded" color="primary" loading={btnLoading} loadingText="授权中" onClick={handleAuthorize}>确定</Button>
    </div>
  )
}

export default Success