import React from 'react'
import Register from '@/pages/register/register'

interface Props{}
// 领取额度
const GetQuota:React.FC<Props> = () => {
  return (
    <Register type="getQuota" />
  )
}

export default GetQuota