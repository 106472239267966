import React from 'react'
import ReactDOM from 'react-dom/client'
// 引入项目里本地图标
import '@/utils/icon'
import './styles/reset.scss'
import Router from '@/router/index'
import { Provider } from 'react-redux'
import { store } from './store/index'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
const BrowserLogger = require('@arms/js-sdk')

const getReleaseStage = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'development'
  } else if (process.env.REACT_APP_API_ENV === 'development') {
    return 'qa'
  } else {
    return 'production'
  }
}
const getEnvironment:() => 'local' | 'pre' | 'prod' = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'local' // local表示本地环境
  } else if (process.env.REACT_APP_API_ENV === 'development') {
    return 'pre' // pre表示预发环境
  } else {
    return 'prod' // prod表示线上环境
  }
}

// Bugsnag.start({
//   apiKey: '',
//   plugins: [new BugsnagPluginReact()],
//   releaseStage: getReleaseStage(),
//   enabledReleaseStages: ['qa', 'production'],
//   onError(error) {
//     // console.log('error', error)

//     // 终止请求之类的错不上报
//     if (error.originalError.message?.startsWith('终止请求')
//     || error.originalError?.includes('授权已过期,请重新登录')
//     || error.originalError?.includes('timeout of 1000ms exceeded')) {
//       return false
//     }
//   }
// })

// const config = {
//   pid: '',
//   appType: 'web',
//   imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
//   sendResource: true,
//   enableLinkTrace: true,
//   behavior: true,
//   enableSPA: false,
//   environment: getEnvironment(),
//   release: ''
// }


// 阿里云前端监控配置 - 暂时关闭：大版本更新可开启查看错误
// const aliyunFEMonitorConfig = {
//   pid: '',
//   appType: 'web',
//   imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
//   sendResource: true,
//   enableLinkTrace: true,
//   behavior: true,
//   environment: getEnvironment()
// }

// BrowserLogger.singleton(aliyunFEMonitorConfig)

// const script2 = document.createElement('script')

// script2.text = `
// !(function(c,b,d,a){c[a]||(c[a]={});c[a].config=${aliyunFEMonitorConfig};
// with(b)with(body)with(insertBefore(createElement("script"),firstChild))setAttribute("crossorigin","",src=d)
// })(window,document,"https://retcode.alicdn.com/retcode/bl.js","__bl");
// `

// document.body.insertBefore(script2, document.body.firstChild)


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

if (process.env.REACT_APP_API_ENV !== 'production') {
  // qa 环境打包后使用 vconsole 来调试
  const loadVConsole = async() => {
    const VConsole = await import('vconsole')
    // eslint-disable-next-line no-new, new-cap
    new VConsole.default()
  }
  loadVConsole()
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router />
    </Provider>
  </React.StrictMode>
)
