import { useEffect } from 'react'

interface Listener {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (...args: any[]): void
}

interface ListenerObject {
  listener: Listener,
  once?: boolean,
}

// 事件映射表
let eventMap: Record<string, ListenerObject[]> = {}

/**
 * 监听事件
 * @param {string}    eventName 事件名
 * @param {function}  listener 回调函数
 * @param {object}    instance 注册事件的实例
 */
function on(eventName: string, listener: Listener) {
  eventMap[eventName] = eventMap[eventName] || []
  eventMap[eventName].push({
    listener,
  })
}

// 监听事件，只执行一次
function once(eventName: string, listener: Listener) {
  eventMap[eventName] = eventMap[eventName] || []
  eventMap[eventName].push({
    listener,
    once: true,
  })
}

// 解除事件监听
function off(eventName: string, listener: Listener) {
  // 解除所有事件监听
  if (!eventName) {
    eventMap = {}
    return
  }

  // 没有对应事件
  if (!eventMap[eventName]) {
    return
  }

  // 解除某事件监听
  eventMap[eventName].forEach((currentEvent, index) => {
    if (currentEvent.listener === listener) {
      eventMap[eventName].splice(index, 1)
    }
  })
}

// 发送事件，执行对应响应函数
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function emit(eventName: string, ...args: any[]) {
  if (!eventMap[eventName]) {
    return
  }

  eventMap[eventName].forEach((currentEvent, index) => {
    currentEvent.listener(...args)
    if (currentEvent.once) {
      eventMap[eventName].splice(index, 1)
    }
  })
}

// 显示当前注册的事件，代码优化时使用
function showEventMap(targetEventName: string) {
  if (targetEventName) { // 查看具体某个事件的监听情况
    eventMap[targetEventName].forEach(eventItem => {
      // console.log(targetEventName, eventItem.listener)
    })
  } else { // 查看所有事件的监听情况
    Object.keys(eventMap).forEach(eventName => {
      eventMap[eventName].forEach(eventItem => {
        // console.log(eventName, eventItem.listener)
      })
    })
  }
}

// useEvent hook
export const useEvent = () => {
  interface EventListenerListObj {
    eventName: string,
    listener: Listener
  }
  // 重载 on 和 once 函数，收集本组件监听的事件，待消除时，销毁事件监听
  const eventListenerList: EventListenerListObj[] = []
  const event = {
    on,
    once,
    off,
    emit,
    showEventMap
  }
  event.on = (eventName, listener) => {
    eventListenerList.push({
      eventName,
      listener
    })
    on(eventName, listener)
  }
  event.once = (eventName, listener) => {
    eventListenerList.push({
      eventName,
      listener
    })
    once(eventName, listener)
  }

  useEffect(() => {
    return () => {
      eventListenerList.forEach(currentEvent => {
        off(currentEvent.eventName, currentEvent.listener)
      })
      eventListenerList.length = 0
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return event
}

const Event = {
  on,
  off,
  once,
  emit,
  showEventMap
}

export default Event