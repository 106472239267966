import React, { useEffect, useState } from 'react'
import Header from '@/components/header/header'
import styles from './detail.module.scss'
import { getParams } from '@bihu/common-js'
// import { isIOS, isAndroid } from '@bihu/common-js/env'
// import { useNavigate } from 'react-router-dom'

interface Props{}

// 新闻详情页
const AuditNewDetail:React.FC<Props> = () => {
//   const navigate = useNavigate()
  const [url, setUrl] = useState('')

  useEffect(() => {
    const res = getParams(undefined, 'url') as string
    setUrl(res)
  }, [])

  // 返回
  //   const back = () => {
  //     try {
  //       if (isIOS) {
  //         window.webkit.messageHandlers.sendNativeMessage.postMessage(JSON.stringify({ action: 'back' }))
  //       } else if (isAndroid) {
  //         window.sendNativeMessage.postMessage(JSON.stringify({ action: 'back' }))
  //       }
  //     } catch (error) {
  //       // eslint-disable-next-line no-console
  //       console.log(error)
  //       navigate(-1)
  //     }
  //   }

  return <div className={styles.auditNewDetail}>
    <Header title="每日新闻" />
    <iframe className={styles.iframe} src={url} title="detail"></iframe>
  </div>
}

export default AuditNewDetail