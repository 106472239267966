// 活动页相关接口
import { api } from '@/utils/axios'
import { EntranceType } from '@/types/apis/index'
interface LoginParams{
  mobile?: string, //手机号码
  code?: string, //短信验证码
  adChannelCode?: string //h5 广告渠道编号
  abTestType?: number, // // 皮肤类型，1-皮肤1、2-皮肤2、皮肤3（非必填）
  isFormReq?: boolean, //是否填写表单类型
  upStreamUnionLoginReqId?: string
}
interface LoginResponse{
  loginUser:{
    uid: string,
    mobile: string,
    userInfoStatus: number,
  },
  token: string
}
interface GetAppDownloadUrlResponse{
  'url-prod': string,
  'url-qa': string,
  'ios': string
}

interface GetNewestVersionParams{
  appType: number, // APP类型，1-元信花yuanxinwallet.com、2-有钱来ningkun8.cn
}

interface GetNewestVersionResponse{
  androidPackageLocation: string, //安卓包链接
  iosPackageLocation: string //iOS链接
}
interface GetAdChannelParams{
  adChannelCode: string
}

interface GetAdChannelResponse{
  linkType: number, //关联链接类型，1-注册-下载，2-注册-万件-下载
  channelStatus: number //广告渠道状态，1-正常，0-禁用
}

interface ReportAdChannelClickParams{
  adChannelCode: string, // 广告渠道编码
  fingerprint: string, // 浏览器指纹标识
  abTestType: number, // 1-皮肤1、2-皮肤2、皮肤3
}

interface ReportProductExposureParams{
  productId: number, // 产品id
  entranceType: EntranceType, // 入口类型，1-首页中插，2-首页弹窗、3-开屏、4-顶部推荐、5-首页列表、6-产品列表、7-随机推荐
  appH5?: number // H5标记
}

const activityApi = {
  // 发送验证码
  smsSend(body = {}) {
    return api.post('/api/login/sendPhoneVerifyCode', body, {
      // mock: true,
    })
  },

  // 注册
  login(body:LoginParams) {
    return api.post<LoginResponse>('/api/login', body, {
      // mock: true,
    })
  },

  // 获取app下载url
  getAppDownloadUrl() {
    return api.get<GetAppDownloadUrlResponse>(`http://yuanxin-app.oss-cn-shenzhen.aliyuncs.com/app-download.json?t=${Date.now()}`, null, {
      mock: false,
      withoutCheck: true
    })
  },

  // 获取最新版本信息（不鉴权）
  getNewestVersion(body: GetNewestVersionParams) {
    return api.get<GetNewestVersionResponse>('/api/appVersion/newestVersion', body, {
      // mock: true,
      showError: false
    })
  },

  // 获取广告渠道信息（不鉴权）
  getAdChannel(body:GetAdChannelParams) {
    return api.get<GetAdChannelResponse | null>('/api/getAdChannel', body, {
      // mock: true,
    })
  },

  // 上报广告渠道点击浏览（不鉴权）
  reportAdChannelClick(body:ReportAdChannelClickParams) {
    return api.get('/api/reportAdChannelClick', body, {
      // mock: true,
    })
  },

  // 上报用户下载APP
  reportUserDownloadApp() {
    return api.get('/api/reportUserDownloadApp', null, {
      // mock: true,
      showError: false,
      withoutCheck: true
    })
  },

  // 用户在线心跳
  health() {
    return api.get('/api/user/health', null, {
      // mock: true,
      withoutCheck: true
    })
  },

  // 上报产品曝光
  reportProductExposure(body:ReportProductExposureParams) {
    return api.get('/api/reportProductExposure', body, {
      // mock: true,
      isCancelRequest: false
    })
  },

  // 根据坐标解析城市信息，若没有传坐标则根据IP解析
  reverseGeo(body:any) {
    return api.get('/api/third/baiduMap/excludeCity', body, {
      // mock: true,
      withoutCheck: true
    })
  },
  // 上报设备信息
  reportDevice(body:any) {
    return api.post('/api/reportDevice', body, {
      // mock: true,
    })
  },
  // 获取用户来源与城市
  getUserDetailVO() {
    return api.get('/api/userApi/getUserDetailVO', null, {
      // mock: true,
    })
  },
  // 获取渠道码详情（不鉴权）
  getChannelCodeDetail(adChannelCode:any) {
    return api.get(`/api/adChannel/plan/adChannelCode?adChannelCode=${adChannelCode}`, null, {
      // mock: true,
    })
  },
}
export default activityApi