/* eslint-disable max-len */
// 隐私协议
import React from 'react'
import styles from './privacy.module.scss'
// import Header from '@/components/header/header'

interface Props {}

const Privacy: React.FC<Props> = () => {
  return (
    <div className={styles.privacy}>
      {/* <Header title="隐私协议" /> */}
      <div>隐私政策</div>
      <div>欢迎您使用我们的产品和服务！我们非常重视您的隐私保护和个人信息保护。本《隐私政策》将帮助您了解以下内容:</div>
      <div>一、引言</div>
      <div>二、我们如何收集和使用您的个人信息</div>
      <p>（一）主要功能及相关个人信息</p>
      <p>（二）改进产品和保障找活找人安全的相关信息</p>
      <p>（三）您授权我们调用设备权限的情形</p>
      <p>（四）征得授权同意的例外</p>
      <div>三、我们如何使用cookie和同类技术</div>
      <div>四、我们如何共享、转让、公开披露您的个人信息</div>
      <p>（一）共享</p>
      <p>（二）转让</p>
      <p>（三）公开披露</p>
      <p>（四）例外情形</p>
      <div>五、我们如何保存及保护您的个人信息</div>
      <div>六、您的权利</div>
      <div>七、我们如何处理未成年人的个人信息</div>
      <div>八、您的个人信息如何进行跨境转移</div>
      <div>九、本《隐私政策》的更新</div>
      <div>十、争议解决</div>
      <div>十一、如何联系我们</div>
      <div>附录：定义</div>
      <div>更新日期：2022年11月30日</div>
      <div>生效日期：2022年11月30日</div>
      <div>版本：2022年11月版</div>

      <div>一、引言</div>
      <div>
        为了切实保护元信花用户隐私权，优化用户体验，元信花根据现行法规及政策，制定本《隐私政策》。本《隐私政策》将详细说明元信花在获取、管理及保护用户个人信息方面的政策及措施。本《隐私政策》适用于元信花公司向您提供的所有服务，无论您是通过计算机设备、移动终端或其他设备获得的元信花服务。
      </div>
      <div>
        本《隐私政策》旨在帮助您了解我们会收集哪些数据、为什么收集这些数据，会利用这些数据做些什么以及如何保护这些数据。我们竭尽全力通过合理有效的信息安全技术及管理流程，防止您的信息泄露、损毁、丢失。我们为您提供便利的信息管理选项，以便您做出合适的选择，管理您的个人信息。为了向您和其他用户提供更好的服务，我们仅收集必要的信息。
      </div>
      <div>
        【特别提示】请您仔细阅读我们的《隐私政策》（尤其是加粗划线的内容），我们希望您在使用元信花服务前仔细阅读并明确您已经充分理解、接受本《隐私政策》的内容，希望您可以根据自己的理解做出合适的选择。您一旦开始使用元信花平台服务，即表示您认同本《隐私政策》中所述内容。在我们更新本《隐私政策》后，您继续使用我们的产品与/或服务，即意味着您同意本《隐私政策》(含更新版本)内容，并且同意我们按照本《隐私政策》收集、使用、保存和共享您的相关信息。此外，当我们将您的个人信息用于本《隐私政策》未涵盖的用途时，我们会事先征求您的同意。
      </div>

      <div className={styles.emptyLine}></div>

      <div>二、我们如何收集和使用您的个人信息</div>
      <div>（一）主要功能及相关个人信息</div>
      <div>
        在您使用元信花提供的以下服务或功能过程中，我们根据合法、正当、必要、透明的原则，基于本政策所述目的，元信花会根据具体场景请求您提供对应的个人信息，该信息主要为您根据自己的意愿自行填写的个人信息，我们会严格依法保护您的个人信息。
      </div>
      <div>1.验证成为注册用户</div>
      <div>
        您需要提供注册元信花用户的拟使用名称及您本人的手机号码，您可以选择微信登录注册账号，我们将使用微信OpenId注册账号，初次微信登录时需绑定手机号码，后续微信登录则不再需要。您也可以通过一键登录创建账号，或者您也可以选择通过手机号码接收验证码注册登录，我们将通过发送短信验证码的方式来验证您的身份是否有效。
      </div>
      <div>2.实名认证</div>
      <div>
        为确保职位发布者身份的真实性和完成实名认证的要求，元信花将进行实名认证，您需要提供实名认证所需必要信息，包括提供真实姓名、性别、年龄、身份证号。如果您是企业用户，那么您还需要提供营业执照以验证单位的合法性。
      </div>
      <div>3.使用找人服务</div>
      <div>
        基于保护找活者安全，当您使用元信花时，我们在首次会通过弹窗申请获取您的位置信息（GPS定位）；经您授权同意后，再获取您的位置权限，如您所在的城市、地区。我们承诺仅收集您当时所处位置信息，不会追踪您的行踪轨迹。如果拒绝授权，您仍可继续使用元信花的其他找活功能。
      </div>
      <div>4.创建在线名片</div>
      <div>
        创建在线名片时（公开），会设置您的头像、填写生日、年龄、家乡、期望项目地、工种、自我介绍、项目经验、职业技能基本名片信息。元信花公司将根据您的以上信息，根据推荐算法为您展示合适的好活。
      </div>
      <div>5.搜索找人/找活信息</div>
      <div>
        您可以通过元信花软件内的搜索功能，尽可能精准地为您提供满足需求或符合偏好的信息。我们会保留您的关键词搜索记录或基于您的使用习惯，避免您再重复输入或为您展示与您搜索内容相关老板或找活用户。
      </div>
      <div>6.购买增值服务</div>
      <div>
        在您选择在线支付购买我们的积分时，您可以选择第三方支付机构所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的购买明细、订单号与交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。具体增值服务政策请以《增值服务协议》为准。
      </div>
      <div>7.拨打电话</div>
      <div>
        当您发布找活或者发布好活后我们会将您的电话给到想要联系您的用户，如果您不希望其他人再联系您，您可以删除掉找活或好活信息，或者联系我们删除。
      </div>
      <div>8.拨打客服和售后服务</div>
      <div>
        当您与我们的客服取得联系时，我们的系统可能会记录您与客服之间的通讯记录，以及使用您的账号信息以便核验身份；当您需要我们提供与您使用相关的客户服务时，我们可能会查询您的相关使用信息以便给予您适当的帮助和处理；当您需要客服协助您修改有关信息（如联系方式、找活名片、好活信息等）时，您可能还需要提供上述信息外的其他信息以便完成修改。
      </div>
      <div>
        为了保证服务体验、处理服务纠纷，您在使用产品过程中拨打或者接听开启号码保护（隐私号）的电话时，您与老板、找活师傅间的通话信息可能会被录音。有关上述信息的收集和使用规则如下，或参见订单页面中的提示内容。
      </div>
      <div>（1）经授权，当您与老板、找活师傅间出现服务纠纷时，平台可调取并使用录音信息作为解决纠纷的参考依据。</div>
      <div>
        （2）平台将切实保障通话双方的隐私安全，录音将上传至元信花服务器保存，各方无法自行下载、调取或播放录音；除以下情形外，平台不会将录音提供给任何人（包括通话主、被叫方）：a.职权机关依法定程序调取；b.录音主体任一方持司法机关出具的法律文件依法调取。
      </div>
      <div>
        （3）通常情况下，录音在保存7天后将自动永久删除，如遇差评、投诉、尚未处理完毕的纠纷等情况时，平台将适当延长录音保存期限。
      </div>
      <div>（二）改进产品和保障找活用户安全的相关信息</div>
      <div>
        为了增强元信花平台的找活找人、在线视频等过程中的安全性，尽可能保障您或其他用户在找人找活过程中的人身、财产安全免遭侵害，我们会收集必要的不涉及到您的个人身份隐私的信息，包括：
      </div>
      <div>
        我们可能会收集您网络服务相关的日志信息，如IP地址、某按钮的点击使用时间和频率、某页面的使用时间和频率、某些关键配置的选项值、崩溃日志、错误日志，用于将您感兴趣的职位、找活用户或找活过程中您可能需要的服务信息展示给您，同时有可能用于统计我们产品的用户数量、分析产品的使用状况、网页升级改版、浏览器兼容性检测、排查崩溃原因、减少崩溃情况。
      </div>
      <div>
        为了验证您的身份信息，防止他人未经授权访问您的账号，我们会在您授权同意后，获取您的手机设备识别码权限，访问您的手机设备型号、IP地址、设备软件版本信息、设备标识符。否则将无法利用身份信息验证的方式保障您的账户安全。
      </div>
      <div>
        我们可能会利用手机信息（包括您的手机号），用于用户之间在线咨询等功能。同时，客服在接受、响应您的咨询、投诉前，会使用您的账号或实名信息核验您的身份。
      </div>
      <div>
        为了维护网络安全、保障平台良好生态，您的IP地址所对应的归属地域信息将会展示在您的信息发布页等位置，境内展示到省(区、市)，境外展示到国家(地区)，IP地址归属地信息以网络运营商提供的为准。
      </div>
      <div>（三）需要您授权同意调取系统权限的情形</div>
      <div>基于保护找人找活过程中的找活者人身安全之考虑，以及满足互联网平台服务普遍存在的风控需求，元信花的附加业务功能可能需要您先同意我们调取相关的系统权限以使用您的个人信息。您可通过手机设备上的“我的-设置-隐私设置-权限管理”逐项查看具体的个人信息访问权限及其开启状态，并决定开启或关闭这些权限。若开启这些权限即代表您授权我们收集和使用这些个人信息：

      </div>
      <div>
        1.读取设备权限
      </div>
      <div>
        我们会收集IMEI，仅用于第三方使用，包括设备标识符（IMEI、IDFA、Android ID、OAID相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）当您使用android版本的元信花APP时，我们会首次向您申请获取设备权限，目的是正常识别您的手机设备识别码，以满足风控、审计需求。若您拒绝，我们可能无法保障您正常使用基本业务功能。
      </div>
      <div>
        2.读取存储权限
      </div>
      <div>
        当您使用android版本的元信花APP、完善注册信息时，在您同意开启外部存储权限后，才可从本地上传图片、照片或文档至元信花APP进行头像设置、文件下载上传操作。否则，您将无法使用与存储卡读取或缓存相关的功能，但您仍可进入元信花继续完善您的注册信息。
      </div>
      <div>
        3.访问相机权限
      </div>
      <div>
        我们访问您的相机是为了您可使用二维码扫描、直接拍摄并上传图片。
      </div>
      <div>
        4.开启定位权限
      </div>
      <div>
        我们会在找活服务中的特定场景以及切换为找人用户期间，使用您的位置信息来实现 “查找附近职位”的业务功能 ，但我们承诺仅收集您所处的位置信息，如您所在的省、市、区（县），而不会追踪您的行踪轨迹。如果拒绝开启位置信息，您仍可使用其他找活业务功能。
      </div>
      <div>
        5.访问相册权限
      </div>
      <div>
        通过开启允许访问相册功能，我们会调用您设备上的相册，用于上传头像、在发布的信息中添加图片、在帮助与反馈页面上传图片，并收集您设备上的图片或视频。当您同意开启此项权限后，可以将相册中的图片作为找人、找活备注，项目经验，职业技能，项目介绍，如果拒绝开启此权限，前述功能将无法正常使用，但不会影响您正常使用其他功能。
      </div>
      <div>
        6.开启通知权限
      </div>
      <div>
        为了能够及时向您发送服务通知，我们会在您授权同意后获取该权限，如果拒绝开启此权限，前述功能将无法正常使用，但不会影响您正常使用其他功能。
      </div>
      <div>
        7.访问麦克风权限
      </div>
      <div>
        我们访问您的麦克风是为了在您与其他找活用户进行发布好活、找活语音自动识别功能。我们只会在为您提供特定的业务场景下申请麦克风权限。在您同意开启麦克风权限后，只有您主动点击相关图标时，我们才会通过麦克风功能获取语音信息。
      </div>
      <div>
        关闭上述系统权限，即代表您撤回这些授权，则我们将不再继续收集和使用您的这些个人信息，但也无法为您提供与这些授权所对应的相关功能。上述权限的关闭决定并不会影响此前基于您的授权所进行的个人信息的处理。
      </div>
      <div>8.获取软件安装列表</div>
      <div>
        我们访问您的软件安装列表是为了让您能够正常分享到微信、QQ。让您能够正常跳转到鱼泡机械业务app、鱼泡招标业务app进行使用。
      </div>
      <div>（四）征得授权同意的例外</div>
      <div>
        根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：
      </div>
      <div>（1）与履行法律法规规定的义务相关的；</div>
      <div>（2）与国家安全、国防安全直接相关的；</div>
      <div>（3）与公共安全、公共卫生、重大公共利益直接相关的；</div>
      <div>（4）与刑事侦查、起诉、审判和判决执行等直接相关的；</div>
      <div>（5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</div>
      <div>（6）所收集的个人信息是您自行向社会公众公开的；</div>
      <div>（7）从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；</div>
      <div>（8）根据您的要求签订和履行合同所必需的；</div>
      <div>（9）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</div>
      <div>（10）用于维护元信花平台产品和/或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；</div>
      <div>（11）法律法规规定的其他情形；</div>
      <div>（12）为实现跨应用打开元信花并完成页面还原功能，我们会共享必要信息给服务提供商（lkme.cc/北京微方程科技有限公司）；</div>
      <div>
        如果您对我们收集和使用您的个人信息有任何疑问或需要提供进一步的信息，请通过本《隐私政策》公布的联系方式与我们联系。
      </div>

      <div className={styles.emptyLine}></div>

      <div>三、我们如何使用cookie和同类技术</div>
      <div>
        为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 cookie 的小数据文件。cookie 通常包含用户身份标识符、城市名称以及一些字符。cookie主要的功能是便于您使用网站产品和服务，以及帮助网站统计独立访客数量等。运用cookie技术，我们能够为您提供更加周到的服务。我们不会将 cookie 用于本《隐私政策》所述目的之外的任何用途。您可根据自己的偏好管理或删除 cookie。您可以清除计算机上保存的所有 cookie，大部分网络浏览器都设有阻止 cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置，但您可能因为该修改，无法登录或使用依赖于cookie的服务或功能。 您可以通过更改您的浏览器设置限制元信花对cookie的使用。以chrome浏览器为例，您可以在chrome浏览器右上方的下拉菜单的“浏览器设置”中，通过“设置-高级-清除浏览数据”，选择清除您的cookie。
      </div>

      <div className={styles.emptyLine}></div>

      <div>四、我们如何共享、转让、公开披露您的个人信息</div>
      <div>
        我们不会向第三方共享、转让您的个人信息，除非经过您本人事先授权同意，或者共享、转让的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。
      </div>
      <div>（一）共享</div>
      <div>
        我们会以高度的勤勉义务对待您的信息。除以下情形外，未经您同意，我们不会与除元信花公司及其关联公司外的任何第三方公司、组织和个人分享您的信息：
      </div>
      <div>1.为向您展示或推荐更多匹配的找活用户或满足期望的职位。</div>
      <div>
        您理解并同意我们将您在元信花展示的全部或部分信息，展示在信息模块供其用户查看、拨打电话及其他类似行为，因此您可能接收到推送消息信息。
      </div>
      <div>
        2.我们承诺在共享过程中，尽最大可能保障您的数据和隐私不受侵害，并以不低于本《隐私政策》所要求的保密和安全措施来处理这些信息。如果您拒绝本条款的内容，请通过本《隐私政策》公布的联系方式与我们联系。
      </div>
      <div>（二）转让</div>
      <div>我们不会将您的个人信息转让给，除元信花公司及其关联公司外的任何公司、组织和个人，但以下情形除外：</div>
      <div>1.基于您自行提出的要求或事先获得您的明确授权或同意；</div>
      <div>2.满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；</div>
      <div>
        3.如果我们或我们的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移时的机密性，并要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
      </div>
      <div>（三）公开披露</div>
      <div>我们仅会在以下情形下，公开披露您的个人信息：</div>
      <div>1.获得您的明确同意；</div>
      <div>2.基于法律法规、法律程序、诉讼或政府主管部门强制性要求下。</div>
      <div>（四）例外情形</div>
      <div>在以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</div>
      <div>1.履行法律法规规定的义务相关的；</div>
      <div>2.与国家安全、国防安全直接相关的；</div>
      <div>3.与公共安全、公共卫生、重大公共利益直接相关的；</div>
      <div>4.与刑事侦查、起诉、审判和判决执行等直接相关的；</div>
      <div>5.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</div>
      <div>6.您自行向社会公众公开的个人信息；</div>
      <div>7.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</div>
      <div>8.根据个人信息主体要求签订和履行合同所必需的；</div>
      <div>9.用于维护所提供的产品或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；</div>
      <div>10.法律法规规定的其他情形。</div>
      <div>
        11.根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
      </div>
      <div>（五）消息提醒</div>
      <div>
        为提升消息的送达率，及时地为您进行消息提醒，我们会默认为您开启关联启动功能，以保持应用的活跃度。如您不想开通此功能，我们建议您手动进行关闭，一般关闭路径：设置-应用-应用启动管理-选择应用“元信花”-关闭关联启动功能。
      </div>

      <div className={styles.emptyLine}></div>

      <div>五、我们如何保存及保护您的个人信息</div>
      <div>(一)保存信息</div>
      <div>1.信息存储</div>
      <div>
          您在使用元信花产品及服务期间，我们将依据《中华人民共和国网络安全法》、《中华人民共和国个人信息保护法》等相关法律法规的规定保存您的信息。在您注销账户或主动删除上述信息后，我们将同步删除您的个人信息，但我们可能会对您的个人信息进行去除个人特征的匿名化处理后用于数据统计的合法目的。
      </div>
      <div>2.保存地域</div>
      <div>
        您的个人信息均储存于中华人民共和国境内。（如需向境外传输您的个人信息，我们会严格按照法律法规的规定执行，并保证您的个人信息安全。）
      </div>
      <div>3.例外情况</div>
      <div>
        一般而言，我们仅为实现目的所必需的合理时间保留您的个人信息，下列情况下，我们有可能因需符合法律要求，更改个人信息的存储时间：
      </div>
      <div>（1）为遵守法律法规等有关规定的适用；</div>
      <div>（2）为遵守法院判决、裁定或其他法律程序的规定；</div>
      <div>（3）为遵守相关政府机关或法定授权组织的要求；</div>
      <div>（4）我们有理由确信需要遵守法律法规等有关规定；</div>
      <div>（5）为执行相关服务协议或本《隐私政策》维护社会公共利益</div>
      <div>（6）为保护我们及我们的客户、用户或雇员的人身财产安全；</div>
      <div>（7）其他合法权益所合理必需的用途。</div>
      <div>
        当我们的产品或服务发生停止运营的情形时，我们将采取例如，推送通知、公告等形式通知您，并在合理的期限内删除或匿名化处理您的个人信息。
      </div>
      <div>(二)保护措施</div>
      <div>
        我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL协议加密保护；我们同时对元信花网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全培训课程，加强员工对于保护个人信息重要性的认识。
      </div>
      <div>我们从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。</div>
      <div>
        我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本《隐私政策》所述目的所需的期限内保留您的个人信息（除非法律有强制的存留要求）。
      </div>
      <div>
        互联网并非绝对安全的环境，使用元信花平台服务时，我们强烈建议您不要使用非元信花平台推荐的通信方式发送您的信息。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、分享时，您可以自主选择沟通、分享的对象，作为能够看到您的联络方式、交流信息或分享内容等相关信息的第三方。
      </div>
      <div>(三)安全事件通知</div>
      <div>
        我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。
      </div>
      <div>
        个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，由安全部、政府关系部、法务部等多个部门组成应急响应小组，在最短时间内追溯原因并减少损失。
      </div>
      <div>
        在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时通过软件内部提醒、短信或电话等方式向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况通过上述方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。
      </div>
      <div>
        我们将不定期更新并公开安全风险、个人信息安全影响评估报告等有关内容，您可通过元信花公告、短信或系统推送等方式获得。
      </div>

      <div className={styles.emptyLine}></div>

      <div>六、您的权利</div>
      <div>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</div>
      <div>(一)访问和修改您的个人信息</div>
      <div>访问修改资料。</div>
      <div>您可以随时修改您的记工、名片等信息。</div>
      <div>(二)删除您的个人信息</div>
      <div>
       在以下情形中，您可以向我们提出删除个人信息的请求，您可以通过“1.访问和修改您的个人信息”中列明的方式或本《隐私政策》公布的联系方式随时与我们联系：
      </div>
      <div>1.如果我们违反法律法规或与您的约定收集、使用、与第三方共享或转让您的个人信息；</div>
      <div>
        2.如果我们违反法律法规规定或与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。
      </div>
      <div>3.我们没有征求您的明确同意，收集了您的个人信息。</div>
      <div>4.我们违反了与您的约定来使用和处理您的个人信息。</div>
      <div>5.您注销了元信花账号、卸载或者不再使用我们的产品（或服务）。</div>
      <div>6.我们停止对您提供服务。</div>
      <div>(三)改变授权范围</div>
      <div>1、改变或撤回敏感信息权限</div>
      <div>您可以在设备本身操作系统中关闭地理位置、摄像头、麦克风等权限改变同意范围或撤回您的授权。</div>
      <div>2、拒绝接受推送信息</div>
      <div>您可以通过手机设置关闭“推送通知”拒绝消息的推送。</div>
      <div>3、关闭个性化推荐</div>
      <div>
        对于找活者，您可通过【会员中心】-【系统设置】-【个性化推荐】关闭相应的个性化推荐服务；当您关闭前述相应的个性化推荐服务后，我们将不再基于您选择的关键词或找活意向您推荐与您较为匹配的职位。
      </div>
      <div>
        4、请您理解，特定的业务功能和服务将需要您的信息才能完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。
      </div>
      <div>(四)注销您的账户</div>
      <div>
        您可以在我们产品的“系统设置&gt账号管理&gt注销账号”中直接申请注销账号，也可以通过本《隐私政策》公布的联系方式，申请注销元信花账号。我们会立即响应您的注销申请，并在您符合注销条件的情况下，在7个工作日内完成注销。注销申请可在操作成功后的7日内撤回，具体操作流程及提示注意等相关内容可以联系客服为您说明。在您主动注销账号之后，我们将停止为您提供产品或服务，并根据法律的要求删除您的个人信息，或对其进行匿名化处理，因法律规定需要留存个人信息的，我们不会再将其用于日常业务活动中。
      </div>
      <div>(五)提前获知产品和服务停止运营</div>
      <div>
        元信花愿一直陪伴您，若因特殊原因导致元信花平台被迫停止运营，我们将按照法律法规的要求，在产品和/或服务的主页面或站内信或向您发送短信或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。
      </div>
      <div>(六)响应您的上述请求</div>
      <div>
        为保障安全，您需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
      </div>
      <div>
        对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
      </div>
      <div>(七)响应请求的例外</div>
      <div>在以下情形中，按照法律法规要求，我们将无法响应您的上述请求：</div>
      <div>1.履行法律法规规定的义务相关的；</div>
      <div>2.与国家安全、国防安全直接相关的；</div>
      <div>3.与公共安全、公共卫生、重大公共利益直接相关的；</div>
      <div>4.与刑事侦查、起诉、审判和执行判决等直接相关的；</div>
      <div>5.证据表明您可能存在明显恶意、滥用权力及占用平台资源的（如您的请求将危害公共安全）；</div>
      <div>6.您和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；</div>
      <div>7.响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；</div>
      <div>8.涉及商业秘密的。</div>

      <div className={styles.emptyLine}></div>

      <div>七、我们如何处理未成年人的个人信息</div>
      <div>元信花非常重视对未成年人信息的保护。</div>
      <div>
        基于我们的产品、网站和服务的性质，我们仅面向成年人提供服务（原则上18周岁以上为成年人，16周岁以上且以自己的劳动收入为主要生活来源的我们亦视为成年人）。
      </div>

      <div className={styles.emptyLine}></div>

      <div>八、您的个人信息如何进行跨境转移</div>
      <div>
        原则上，我们在中国境内收集和产生的个人信息仅存储在中国境内。如部分产品或服务涉及跨境，我们需要向境外传输您的个人信息，我们会严格按照法律法规的规定执行，并保证您的个人信息安全。
      </div>

      <div className={styles.emptyLine}></div>

      <div>九、本《隐私政策》的更新</div>
      <div>
        我们可能适时修订本《隐私政策》内容。如该等变更会导致您在本《隐私政策》项下权利的实质变化，若您不同意该等变更应停止使用元信花平台产品和服务，请及时联系我们暂时封停您的账号。若您继续使用我们的产品或服务，即表示您同意受修订后的本《隐私政策》的约束；
      </div>
      <div>本《隐私政策》所指的重大变更包括但不限于：</div>
      <div>1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</div>
      <div>2.个人信息共享、转让或公开披露的主要对象发生变化；</div>
      <div>3.您参与个人信息处理方面的权利及其行使方式发生重大变化；</div>
      <div>4.其他可能对您的个人信息权益产生重大影响的变化时；</div>
      <div>5.个人信息出境情况发生变更时。</div>

      <div className={styles.emptyLine}></div>

      <div>十、争议解决</div>

      <div>
        本《隐私政策》与《元信花用户协议》共同构成您使用元信花服务的基本协议文件。本《隐私政策》适用中华人民共和国现行法律法规。与本《隐私政策》相关的争议，如果您对我们的回复或解决方案不满意，需要通过法律途径解决的，您在此同意将该争议提交至成都仲裁委员会，其仲裁裁决是终局的。
      </div>

      <div className={styles.emptyLine}></div>

      <div>十一、如何联系我们</div>
      <div>
        您可以通过以下方式与我们联系，我们将在15天内答复您的请求：您可以通过网站 (http://h5.ningkun8.cn/audit-agreement-contact）/APP上提供的在线联系方式/客服系统与我们联系，您可以联系元信花的客服电话 （13316914762） 进行反馈。
      </div>
      <div>附录：定义</div>
      <div>本《隐私政策》中使用的特定词语，具有如下含义</div>
      <div>1.“我们”或“元信花公司”，指成都鱼泡科技有限公司</div>
      <div>2.“关联公司”，指成都鱼泡科技有限公司。</div>
      <div>
        3.“您”或“用户”，指使用成都鱼泡科技有限公司运营的平台产品或服务的注册用户以及收费服务的购买方。
      </div>
      <div>
        4.“元信花平台”或“元信花软件”，指元信花PC端及元信花手机客户端、元信花微信小程序、百度小程序、抖音小程序、记工记账小程序、元信花m端。
      </div>
      <div>5.“元信花关联方”，指海南柠坤科技有限公司单独运营的平台。</div>
      <div>
        6.“个人信息”，指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情況的各种信息。个人信息包括个人基本信息、个人身份信息、网络身份标识信息、个人简介、个人工种信息、个人项目经验信息、个人职业技能信息、个人通信信息、联系人信息、个人上网记录、个人常用设备信息、个人位置信息等。为免疑以，个人信息包括但不限于个人敏感信息
      </div>
      <div>7.“个人信息主体”，指个人信息所标识的自然人。</div>
      <div>
        8.“个人敏感信息”，指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。个人敏感信息包括个人财产信息、个人身份信息、网络身份标识信息等。
      </div>
      <div>9.“去标识化”，指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程</div>
      <div>10.“磨名化”，指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程</div>
      <div>
        11.“中国”或“中国境内”，指中华人民共和国大陆地区，仅为本《隐私政策》之目的，不包含香港特别行政区、澳门特别行政区和台湾地区。
      </div>
    </div>
  )
}

export default Privacy
