// 审核新闻页面
import auditNewApi from '@/apis/audit-new'
import React, { useEffect, useState } from 'react'
import styles from './audit-new.module.scss'
import { InfiniteScroll } from 'antd-mobile'
import { interfacePrefix } from '@/utils/axios'
import { useNavigate } from 'react-router-dom'

interface Props {}

const AuditNew: React.FC<Props> = () => {

  const navigate = useNavigate()

  const [list, setList] = useState([])
  const [page, setPage] = useState(1)

  useEffect(() => {
    getList({
      page
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  interface Params {
    page: number,
  }

  const getList = async(params: Params) => {

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res:any = await auditNewApi.getList({
        url: encodeURIComponent(`https://api.rebang.today/v1/items?tab=tianya&page=${params.page}`)
      })
      // console.log(res)

      if (params.page === 1) {
        setList(res.list)
      } else {
        setList(list.concat(res.list))
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error:any) {
      // console.log('error', error)

      // eslint-disable-next-line no-magic-numbers
      if (error.code === 1003) {
        setHasMore(false)
      }
    }
  }

  const goto = (url:string) => {
    navigate(`/audit-new/detail?url=${url}`)
    // window.location.href = url
  }

  const getUrl = (url:string) => {
    return `${interfacePrefix}api/agent/get?url=${encodeURIComponent(`https://img.rebang.today/${url}`)}`
  }

  const [hasMore, setHasMore] = useState(true)
  async function loadMore() {
    await getList({
      page: page + 1
    })
    setPage(page + 1)
  }

  return (
    <div className={styles.auditNew}>
      {/* <div className={styles.title}>每日新闻</div> */}
      <div className={styles.content}>
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          list.map((item:any) => {
            return (
              <div className={styles.row} key={item.title} onClick={() => goto(item.url)}>
                {
                  item.img && <div className={styles.left}>
                    <img className={styles.img} src={getUrl(item.img)} alt="img" />
                  </div>
                }
                <div className={styles.right}>
                  <div className={styles.name}>{item.title}</div>
                  <div className={styles.desc}>{item.desc}</div>
                </div>
              </div>
            )
          })
        }
      </div>
      {
        list.length > 0 && <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
      }
    </div>
  )
}

export default AuditNew
