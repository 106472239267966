import React, { useState } from 'react'
import Icon from '../icon/icon'
import styles from './header.module.scss'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
interface Props{
    title: string,
    backCallBack?: () => void,
    className?: string,
    showHeaderBack?: boolean,
    hiddenHeaderBottomLine?: boolean,
    headerColor?:string
}

const Header: React.FC<Props> = props => {
  const { title, backCallBack = null, className = '', showHeaderBack = true, hiddenHeaderBottomLine = false, headerColor = '' } = props
  const navigate = useNavigate()

  const back = () => {
    if (backCallBack) {
      backCallBack()
    } else {
      navigate(-1)
    }
  }

  return (
    <header className={classNames(styles.header, className)} style={{
      borderBottom: hiddenHeaderBottomLine ? 'none' : '',
      backgroundColor: headerColor
    }}>
      { showHeaderBack && <div className={styles.back} onClick={back}>
        <Icon type="back" />
      </div> }
      <span>{title}</span>
    </header>
  )
}

export default Header