/* eslint-disable no-magic-numbers */
// jumpUrl中转页
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { Button } from 'antd-mobile'
import styles from './transfer.module.scss'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
interface Props {}

const IosSkin: React.FC<Props> = props => {
  const [search] = useSearchParams()
  const options:any = search.get('options')
  let resData:any = {}
  try {
    resData = JSON.parse(decodeURIComponent(options))
  } catch {
    resData = JSON.parse(options)
  }

  const { productName } = resData
  const { applyStatus } = resData //是否hold单，状态为8代表hold单
  const { jumpUrl } = resData

  // 倒计时
  const [countdownTime, setCountdownTime] = useState<number>(5)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  // 倒计时
  useEffect(() => {
    if (countdownTime > 0) {
      timer.current = setTimeout(() => {
        setCountdownTime(countdownTime - 1)
      }, 1000)
    } else {
      toThirdPartyPage()
    }
  }, [countdownTime])

  const toThirdPartyPage = () => {
    window.location.href = jumpUrl
  }

  return (
    <div className={styles.iosSkin}>
      <div className={styles.main}>
        <div className={styles.logoBox}>
          <img className={styles.logo} src={require('@imgs/youqianqianbao-logo.png')} alt="logo" />
          <div className={styles.logoBoxRight}>
            <img className={styles.youqianLogoText} src={require('@imgs/youqianqianbao-text.png')} alt="youqian-text" />
            <img className={styles.logoText2} src={require('@imgs/register/new-theme/logo-subtitle.png')} alt="正规品牌 真实可信" />
          </div>
        </div>
        {
          productName ? <>
            <div className={styles.applyResultBox}>
              <img className={styles.applyResultBoxIcon} src={require('@imgs/ios-skin/success.png')} alt="success" />
              {
                applyStatus === 8 ? <>
                  <div className={styles.applyResultBoxRight}>
                    <div className={styles.applyResultBoxText1}>恭喜, <span className={styles.applyPrductionName}>{productName}</span>匹配成功！</div>
                    <div className={styles.applyResultBoxText2}>审核人员将在<span className={styles.highlight}>工作时间来电</span>完成审核</div>
                  </div>
                </> : <>
                  <div className={styles.applyResultBoxRight}>
                    <div className={styles.applyResultBoxText1}>恭喜, <span className={styles.applyPrductionName}>{productName}</span>申请成功！</div>
                    <div className={styles.applyResultBoxText2}>请留意审核人员来电，完成信息审核</div>
                  </div>
                </>
              }
            </div>
            <div className={classNames(styles.containers, styles.passBox)}>
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/pass.png')} alt="pass" />
                <div className={styles.containerItemSuccessText}>申请成功</div>
              </div>
              <img className={styles.line} src={require('@imgs/form-result/line.png')} alt="line" />
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/phone.png')} alt="phone" />
                <div className={styles.containerItemFailText}>请留意审核人员来电</div>
              </div>
            </div>
          </> : <>
            <div className={classNames(styles.containers, styles.failBox)}>
              <img className={styles.noPass} src={require('@imgs/form-result/empty.png')} alt="no-pass" />
              <div className={styles.failBoxText1}>名额已满</div>
              <div className={styles.failBoxText2}>抱歉，机构名额已满</div>
              <div className={styles.failBoxText2}>无法为您提供服务</div>
            </div>
          </>
        }
        <div className={styles.btnBox}>
          <Button block className={styles.btn} shape="rounded" color="primary" onClick={toThirdPartyPage}>
            { `我知道了${countdownTime ? `(${countdownTime}s)` : ''}`}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default IosSkin
