import { TOKEN } from '@/constants/storage'
import { Storage } from '@bihu/common-js'

const storage = new Storage(TOKEN, 'String')

/**
 * 获取 token
 * @returns {any} token
 */
export const getToken = () => storage.get()

/**
 * 保存 token
 * @param {string} token token
 */
export const setToken = (token: string) => {
  storage.set(token)
}

/** 移除 token */
export const removeToken = () => {
  storage.remove()
}
