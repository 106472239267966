// 组件注释
import React, { useEffect, useState, useImperativeHandle } from 'react'
import styles from './user-info-form.module.scss'
import { Form, Input, CascadePickerView, Toast, Button, Popup } from 'antd-mobile'
import { validateIDNumber } from '@bihu/common-js'
import classNames from 'classnames'
import cityDate from '@/assets/js/city'
import type { PickerValue, PickerValueExtend } from 'antd-mobile/es/components/picker'
import { FormItem } from '../../new-fill-information'

export interface OptionType{
  label: string,
  value: string,
  num?:number
}

interface Props {
  // 当前步骤
  step: number,
  // 传入的资产信息列表
  formItemList: Array<FormItem>
  // 修改
  onChange: (obj: OptionType, formItem: FormItem, index: number) => void,
  onChangeFormValue: (type: string, val: any) => void,
  // 名字
  realName: string, //真实姓名
  identity: string, //身份证
  cityShow: string, // 城市input展示字段
  cityValue: (string | null)[], //城市编码
  cityConfirm: (value: PickerValue[], extend: PickerValueExtend) => void, //修改城市
  confirm: () => void // 表单2身份验证成功
  formType: 'form' | 'popup',
  isSubimtWaiting: number,
  waitingProgress:number
}

export interface UserInfoFormComponentHandles {
  btnUpdate: () => void
}

const UserInfoFormComponent = React.forwardRef<UserInfoFormComponentHandles, Props>(
  ({ step, formItemList, onChange, onChangeFormValue, realName, identity, cityShow, cityValue, cityConfirm, confirm, formType, isSubimtWaiting, waitingProgress }, ref) => {
    const [collapse, setcollapse] = useState(step) // 当前展开折叠索引
    const [isShowList, setisShowList] = useState(1) // 信息表单控制展开
    const [cityVisible, setCityVisible] = useState(false) // 城市
    const [cascadePickerValue, setCascadePickerValue] = useState<PickerValue[]>(cityValue)
    const [cascadePickerExtend, setCascadePickerExtend] = useState<PickerValueExtend>({
      columns: [],
      items: []
    }) // 城市选择的
    const [form] = Form.useForm() // 表单

    // 身份证验证
    const checkIdentity = (_: any, value: string) => {
      if (value) {
        if (!validateIDNumber(value)) {
          return Promise.reject(new Error('身份证号错误，请重新输入'))
        }
        return Promise.resolve()
      }
      return Promise.reject(new Error('请输入身份证号'))
    }

    // 居住城市验证
    const checkLivingCity = (_: any, value: string) => {
      if (!cityShow) {
        return Promise.reject(new Error('请选择居住城市'))
      }
      return Promise.resolve()
    }
    useEffect(() => {
      setcollapse(step)
    }, [step])

    // 控制当前展示选择栏
    const isShowCollapse = (index: number) => {
      return (index + 1) <= step
    }

    // 选择展开折叠索引
    const chooseCollapse = (index: number) => {
      setcollapse(collapse === index + 1 ? 0 : index + 1)
    }

    // 选择信息完成
    const chooseInfoItem = (option: OptionType, item: FormItem, index: number) => {
      setcollapse(collapse + 1)
      onChange(option, item, index)
    }

    useEffect(() => {
      if (formItemList[step - 1]?.key === 'lastPage') {
        setisShowList(2)
      }
    }, [step])

    useEffect(() => {
      // 当 realName 或 identity 改变时，更新表单字段
      if (formItemList[step - 1]?.key === 'lastPage') {
        form.setFieldsValue({
          realName,
          identity
        })
      }
    }, [realName, identity, form])

    // 提交表单校验
    const btnUpdate = () => {
      form.validateFields().then(obj => {
        confirm()
      })
        .catch(error => {
          if (error) {
            let filed = Object?.keys(error.errorFields[0])[1]
            Toast.show(error.errorFields[0][filed][0])
          }
          const dom = document.getElementById('app')
          if (dom) {
            dom.scrollTo(0, 0)
          }
        })
    }

    useEffect(() => {
      setCascadePickerValue(cityValue)
    }, [cityValue])

    // 向父组件暴露方法
    useImperativeHandle(ref, () => ({
      btnUpdate
    }))
    return (
      <div className={styles.form}>
        <div className={styles.userInfoItem}>
          <div>
            <div className={classNames(styles.assetInfomation, styles.border)} onClick={() => setisShowList(isShowList === 1 ? 0 : 1)}>
              <span className={styles.flex}>
                <img src={require('@imgs/form-result/wallet.png')} className={styles.walletIcon} alt="钱包" />
                <span className={classNames(styles.assetNum)}>资产信息  ( {step - 1 } / {formItemList.length - 1 } ) </span>
              </span>
              <img style={{ transform: `rotate(${isShowList === 1 ? 90 : 0}deg)` }} className={styles.arrow} src={require('@imgs/form-result/right.png')} alt="资产信息" />
            </div>
            {
              isShowList === 1 && <div className={styles.formList}>
                {
                  formItemList.map((item, index) => {
                    return item.key !== 'lastPage' && <React.Fragment key={item?.key + index}>
                      {
                        isShowCollapse(index) && <div className={styles.formItem} key={item?.key + index}>
                          <div className={styles.formItemTitle} onClick={() => chooseCollapse(index)}>
                            <span className={styles.leftItemBox}>
                              {item.name}
                            </span>
                            <span className={styles.flex}>
                              { item.value
                                ? <span className={styles.chooseLabel}>{ item.options.find((option:OptionType) => option.value === item.value)?.label }</span>
                                : <span className={styles.placeChoose}>请选择</span>
                              }
                              <img style={{
                                transform: `rotate(${collapse === index + 1 ? 90 : 0}deg)`,
                              }} className={styles.arrow} src={require('@imgs/form-result/right.png')} alt="借款金额" />
                            </span>
                          </div>
                          {
                            collapse === index + 1 && <div className={styles.formItemContent}>
                              {
                                item.options.map((option:OptionType) => (
                                  <div
                                    key={option.value + option.num}
                                    className={classNames(styles.formItem, option.value === item.value && styles.activeItem)}
                                    onClick={() => chooseInfoItem(option, item, index)}>
                                    {option.label}
                                  </div>
                                ))
                              }
                            </div>
                          }
                        </div>
                      }</React.Fragment>
                  })
                }
              </div>
            }
          </div>

          {formItemList[step - 1]?.key === 'lastPage'
        && <div>
          <div className={styles.assetInfomation} onClick={() => setisShowList(isShowList === 2 ? 0 : 2)}>
            <span className={styles.flex}>
              <img src={require('@imgs/form-result/address.png')} className={styles.walletIcon} alt="youqianqianbao-wallet" />
              <span className={classNames(styles.assetNum)}>基本信息</span>
            </span>
            <img style={{ transform: `rotate(${isShowList === 2 ? 90 : 0}deg)` }} className={styles.arrow} src={require('@imgs/form-result/right.png')} alt="资产信息" />
          </div>
          <Form
            style={{ display: isShowList === 2 ? 'block' : 'none' }} // 控制表单可见性
            form={form}
            layout="horizontal"
          >
            <div className={styles.lastBoxItem}>
              <div className={styles.flex}>
                <img src={require('@imgs/form-result/name.png')} className={styles.icon} alt="youqianqianbao-name" />
                <Form.Item
                  label="真实姓名"
                  name="realName"
                  rules={[
                    {
                      validateTrigger: 'onblur',
                      required: true
                    },
                  ]}
                >
                  <Input maxLength={4} value={realName} placeholder="请输入真实姓名" onBlur={val => {
                    onChangeFormValue('realName', val.target.value)
                  }} />
                </Form.Item>
              </div>
              <div className={styles.flex}>
                <img src={require('@imgs/form-result/card.png')} className={styles.icon} alt="youqianqianbao-card" />
                <Form.Item
                  label="身份证号"
                  name="identity"
                  rules={[
                    {
                      validateTrigger: 'onBlur',
                      validator: checkIdentity
                    }
                  ]}
                >
                  <Input
                    placeholder="请输入身份证号"
                    maxLength={18}
                    value={identity}
                    onBlur={val => {
                      onChangeFormValue('identity', val.target.value)
                    }} />
                </Form.Item>
              </div>
              <div className={styles.flex}>
                <img src={require('@imgs/form-result/city.png')} className={styles.icon} alt="youqianqianbao-city" />
                <Form.Item
                  label="当前城市"
                  name="livingCity"
                  trigger="onConfirm"
                  className={styles.city}
                  rules={[
                    {
                      validator: checkLivingCity
                    }
                  ]}
                  onClick={e => {
                    setCityVisible(true)
                  }}>
                  <>
                    <Input placeholder="请选择城市" readOnly value={cityShow} />
                    <Popup
                      visible={cityVisible}
                      onMaskClick={() => {
                        setCityVisible(false)
                      }}
                      bodyStyle={{
                        minHeight: '30vh',
                      }}
                    >
                      <div className={styles.btnList}>
                        <Button style={{
                          color: '#666666',
                          fontSize: '16px'
                        }} fill="none"
                        onClick={() => {
                          setCityVisible(false)
                        }}>取消</Button>
                        <Button
                          style={{
                            color: '#FF041D',
                            fontSize: '16px'
                          }}
                          fill="none"
                          onClick={() => {
                            cityConfirm(cascadePickerValue, cascadePickerExtend)
                            setCityVisible(false)
                          }}
                        >确认</Button>
                      </div>
                      <p className={styles.noticle}>温馨提示：机构不支持跨城市申请，请选择您居住/工作所在城市</p>
                      <CascadePickerView
                        options={cityDate}
                        value={cascadePickerValue}
                        onChange={(e, v) => {
                          setCascadePickerExtend(v)
                          setCascadePickerValue(e)
                        }}
                      />
                    </Popup>
                  </>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
          }
        </div>
        {formItemList[step - 1]?.key === 'lastPage' && formType === 'form'
          && <Button className={styles.btn} onClick={btnUpdate} block color="primary" shape="rounded" size="large">
            {
              // eslint-disable-next-line no-nested-ternary
              isSubimtWaiting === 0 ? '提交借贷' : isSubimtWaiting === 1 ? `信息认证中...${waitingProgress}%` : isSubimtWaiting === 2 ? '信息认证中...100%' : '信息认证成功'
            }
          </Button>
        }
      </div>
    )
  })

export default UserInfoFormComponent
