/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Button, Toast, Popup } from 'antd-mobile'
import styles from './authorize-page.module.scss'
import Header from '@/components/header/header'
import classNames from 'classnames'
import ProductList from '@/components/product-list/product-list'
import productApi, { ProductListResponse, ProductListParams } from '@/apis/product'
import { ENTRANCE_TYPE } from '@/constants/common'
import { isIOS, isInWeChat, isMobile, download, Storage } from '@bihu/common-js'
import activityApi from '@/apis/activity'
import { useSearchParams, useNavigate } from 'react-router-dom'
import copy from 'copy-to-clipboard'
import { ENTER_AGREEMENT } from '@/constants/storage'
import indexApi from '@/apis'
import ProtocolIdsConfirm1 from '../protocolIds-confirm-1/protocolIds-confirm-1'
import ProtocolIdsConfirm2 from '../protocolIds-confirm-2/protocolIds-confirm-2'
import useFirstOrAfter from '@/hooks/use-first-or-after'
import { selectUserInfo } from '@/store/user'
import { useAppSelector } from '@/store/hooks'

interface Props{}

const Success:React.FC<Props> = () => {
  const ProtocolIdsConfirm1ModalRef: any = useRef()
  const ProtocolIdsConfirm2ModalRef: any = useRef()
  const userInfo = useAppSelector(selectUserInfo)
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const pageOptions:any = search.get('options')
  let resData = JSON.parse(decodeURIComponent(pageOptions)) || {}
  let tempThirdProducts = resData.thirdProducts?.map((item:any) => {
    item.checked = false
    item.lendingRateString = Math.round(Math.random() * 3 + 95)
    return item
  })
  const [detail, setDetail] = useState(resData)
  const [thirdProducts, setThirdProducts] = useState(tempThirdProducts)

  // 渠道编码
  const [adChannelCode, setAdChannelCode] = useState('')
  const [apiChannelCode, setApiChannelCode] = useState('')
  const [serialNo, setSerialNo] = useState('')
  const [jumpUrl, setJumpUrl] = useState('')
  const [applyLimit, setApplyLimit] = useState(0)

  useEffect(() => {
    const code = search.get('adChannelCode')
    if (code) {
      setAdChannelCode(code)
      localStorage.setItem('ad_channel_code', code)
    }

    let urlApiChannelCode:any = search.get('apiChannelCode')
    let urlSerialNo:any = search.get('serialNo')
    if (urlApiChannelCode) {
      setSerialNo(urlSerialNo)
      setApiChannelCode(urlApiChannelCode)
      localStorage.setItem('ad_channel_code', urlApiChannelCode)
      localStorage.setItem('hostName', '有钱来')
    }

    let urlJumpUrl:any = search.get('jumpUrl')
    if (urlJumpUrl) {
      const tempJumpUrl = JSON.parse(decodeURIComponent(urlJumpUrl))
      setJumpUrl(tempJumpUrl)
    }

    let urlApplyLimit:any = search.get('applyLimit')
    if (urlApplyLimit) {
      setApplyLimit(urlApplyLimit)
    }
  }, [])

  // 是否华为手机
  const [isHuaWeiPhone, setIsHuaWeiPhone] = useState<boolean>(false)

  useEffect(() => {
    const tempIsHuaWeiPhone = search.get('isHuaWeiPhone')
    if (tempIsHuaWeiPhone) {
      setIsHuaWeiPhone(tempIsHuaWeiPhone === 'true')
    }
  }, [])

  const monthUnitMap:any = {
    1: '分钟',
    2: '小时',
    3: '天',
  }

  const rateUnitMap:any = {
    1: '日利率',
    2: '月利率',
    3: '年利率',
  }
  // 协议ID
  const [agreementId, setAgreement] = useState('')
  // 协议列表
  const [agreementList, setAgreementList] = useState([])

  // 选择协议
  const [checked, setChecked] = useState(false)

  // 切换选中协议
  const checkedTaggle = () => {
    setChecked(!checked)
  }

  // 按钮loading
  const [btnLoading, setBtnLoading] = useState(false)

  // 第三方产品
  const checkedThirdProductsTaggle = (item:any, index:any) => {
    thirdProducts[index].checked = !item.checked
    setThirdProducts([...thirdProducts])
  }

  // 跳转页面
  const openView = (path: string) => {
    Storage.set(ENTER_AGREEMENT, true)
    window.location.href = `/${path}-agreement?isShowHeader=true`
  }

  // 跳转页面
  const openOtherView = (url: string) => {
    Storage.set(ENTER_AGREEMENT, true)
    window.location.href = url
  }

  const submitApplication = async(isNeedChecked = true) => {
    try {
      let thirdProductIds:any = []
      thirdProducts.forEach((item:any) => {
        if (item.checked) {
          thirdProductIds.push(item.productId)
        }
      })
      if (!thirdProductIds.length) {
        Toast.show('请勾选需要申请的产品')
        return
      }
      if (!checked && isNeedChecked) {
        ProtocolIdsConfirm2ModalRef.current.init({
          youqianlaiProtocol,
          otherProtocols,
          productId: detail.id,
        })
        return
      }
      let protocolIds = detail.protocols?.map((item:any) => {
        return item.id
      })
      //获取在登录页时保存的百度渠道的全链接
      const bdVidUrl = localStorage.getItem('baiduVistLink') || ''
      setBtnLoading(true)
      // 前端日志上报
      const reportParam = `uid:${userInfo?.uid},adChannelCode:${adChannelCode || apiChannelCode},event:授权点击`
      indexApi.reportLog(reportParam)
      // 信息授权
      const res:any = await indexApi.confirmAuthorize({
        productId: detail.id,
        protocolIds,
        agree: true,
        adChannelCode,
        apiChannelCode,
        isHuaWeiPhone,
        thirdProducts: thirdProductIds,
        bdVidUrl,
        orderNum: detail.orderNum || 0, //第几回授权
        serialNo: detail.serialNo || serialNo
      })
      setBtnLoading(false)
      handleAduitSuccess(res)
    } catch (error) {
      setBtnLoading(false)
    }
  }

  // 修改页面url
  const reFreshPageUrl = (newRqResult:any) => {
    const pageUrl = window.location.origin + window.location.pathname
    let modifiedUrl = ''
    // 如果是API采量授权方式
    if (apiChannelCode) {
      modifiedUrl = `${pageUrl}?options=${encodeURIComponent(JSON.stringify(newRqResult))}&apiChannelCode=${apiChannelCode}&applyLimit=${newRqResult.applyLimit}&jumpUrl=${encodeURIComponent(JSON.stringify(jumpUrl))}&serialNo=${serialNo}`
    } else {
      modifiedUrl = `${pageUrl}?options=${encodeURIComponent(JSON.stringify(newRqResult))}&adChannelCode=${adChannelCode}&isHuaWeiPhone=${isHuaWeiPhone}&applyLimit=${newRqResult.applyLimit}`
    }

    window.history.replaceState(null, 'null', modifiedUrl)
  }

  const handleAduitSuccess = async(res:any) => {
    // 正常授权且有结果
    if (res && res.applyStatus !== 2) {
      if (jumpUrl) {
        res.jumpUrl = jumpUrl
        window.location.href = `/fill-information/success-transfer?options=${encodeURIComponent(JSON.stringify(res))}`
        return
      }
      // 如果是API转H5类型，则直接跳转第三方链接
      if (res.thirdpartyType === 4 && res.thirdpartyTargetUrl) {
        await indexApi.apiH5JumpUrlSuccessFlag({
          uid: userInfo?.uid,
          productId: detail.id,
          productName: detail.productName,
          thirdpartyTargetUrl: res.thirdpartyTargetUrl
        })
        window.location.href = res.thirdpartyTargetUrl
        return
      }
      // 如果授权失败，但是有返回下一个产品，就重新授权
      if (res.applyStatus === 4 && res.productNextMatchDTO) {
        // productNextMatchDTO没有返回serialNo，需要保存
        if (detail.serialNo) {
          res.productNextMatchDTO.serialNo = detail.serialNo
        }
        // 判断是否匹配回调类型(如：惠逸花、惠逸花A)
        if (res.productNextMatchDTO.thirdpartyType !== 5) {
          // 跳回API采量授权页
          // eslint-disable-next-line max-depth
          if (apiChannelCode) {
            window.location.replace(`/fill-information/common-authorize?apiChannelCode=${apiChannelCode}&jumpUrl=${encodeURIComponent(JSON.stringify(jumpUrl))}&authNum=${res.productNextMatchDTO.orderNum}&rqResult=${encodeURIComponent(JSON.stringify(res.productNextMatchDTO))}&serialNo=${serialNo}`)
            return
          }

          // 跳回普通授权页
          let tempOptions = {
            adChannelCode,
            isHuaWeiPhone,
            applyLimit,
            workCity: res.workCity,
            rqResult: res.productNextMatchDTO
          }
          window.location.replace(`/fill-information/common-authorize?options=${encodeURIComponent(JSON.stringify(tempOptions))}&authNum=${res.productNextMatchDTO.orderNum}`)
          return
        }
        setDetail(res.productNextMatchDTO)
        const newThirdProducts = res.productNextMatchDTO?.thirdProducts?.map((item:any) => {
          item.checked = false
          item.lendingRateString = Math.round(Math.random() * 3 + 95)
          return item
        })
        setChecked(false)
        setThirdProducts([...newThirdProducts])
        Toast.show('申请名额已满，您的匹配机构已更新，请继续操作')
        reFreshPageUrl(res.productNextMatchDTO)
        return
      }

      window.location.href = `/fill-information/success?options=${encodeURIComponent(JSON.stringify(res))}`
      return
    }

    // 结果页
    // 走这里说明是重复授权或者授权失败，重复授权也需要判断res
    const options:any = res ? res : { rePageShowType: adChannelCode === '9o0w2B' && isHuaWeiPhone ? 2 : 0 }
    window.location.href = `/fill-information/success?options=${encodeURIComponent(JSON.stringify(options))}`
  }

  const [agreementContent, setAgreementContent] = useState('')
  const [youqianlaiProtocol, setYouqianlaiProtocol] = useState<any>('')
  const [otherProtocols, setotherProtocols] = useState([])
  useEffect(() => {
    let newProtocols:any = []
    // eslint-disable-next-line array-callback-return
    detail && detail.protocols?.map((item:any, index:number) => {
      if (item.protocolType !== 1) {
        newProtocols.push(item)
      } else if (item.protocolType === 1) {
        setYouqianlaiProtocol(item)
      }
    })
    setotherProtocols(newProtocols)
  }, [detail])
  useEffect(() => {
    async function getAgreementInfo() {
      const res: any = await indexApi.getAgreementInfo(youqianlaiProtocol.id, detail.id)
      if (adChannelCode) {
        // 如果是快手渠道，将公司主题改为恒盛融资担保（福州）有限公司
        const kuaishouCode = ['EQrvFe', 'rYoXK7', 'CG5Gd7']
        if (kuaishouCode.indexOf(adChannelCode) !== -1) {
          res.content = res.content.replace(/深圳云享钱包科技有限公司/g, '恒盛融资担保（福州）有限公司')
        }
      }
      setAgreementContent(res.content)
    }

    if (youqianlaiProtocol) {
      getAgreementInfo()
    }

  }, [youqianlaiProtocol])

  // 是否显示弹出层
  const [protocolIdsVisible, setProtocolIdsVisible] = useState(false)

  // 弹起协议相关列表
  const openProtocolsPopup = () => {
    setProtocolIdsVisible(true)
  }

  const handleLoginSuccess = (res:any) => {
    //
  }

  const handelSubmitData = () => {
    setChecked(true)
    submitApplication(false)
  }

  useFirstOrAfter(() => {
    localStorage.removeItem('productBack')

    if (!Storage.get('isRefreshLoadPage') && !search.get('refresh')) {
      Storage.set('isRefreshLoadPage', true)
      // window.location.href = `${document.URL}&refresh=1`
      return
    } else {
      Storage.set('isRefreshLoadPage', false)
    }

    // 监听浏览器回退事件
    window.addEventListener('popstate', back, false)
    // window.onpopstate = back
    window.history.pushState(null, 'null', document.URL)

    return () => {
      // 回退事件只用于当前组件，则需要在组件销毁时把回退事件销毁
      window.removeEventListener('popstate', back, false)
      // window.onpopstate = null
    }
  }, () => {
  // eslint-disable-next-line no-console
    console.log('afterFn')
  }, [])

  // 返回
  const back = () => {
    const isShowPopupStorage = Storage.get('isShowPopup')

    try {
      if (isShowPopupStorage) {
        Storage.set('isShowPopup', false)
        navigate(-1)
      } else {
        //  阻止浏览器回退事件
        openProtocolIdsConfirm1Modal()
        Storage.set('isRefreshLoadPage', false)
        Storage.set('isShowPopup', true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  // 打开挽留弹窗
  const openProtocolIdsConfirm1Modal = () => {
    let tempYouqianlaiProtocol = ''
    let temPotherProtocols:any = []
    // eslint-disable-next-line array-callback-return
    detail.protocols?.map((item:any) => {
      if (item.protocolType !== 1) {
        temPotherProtocols.push(item)
      } else if (item.protocolType === 1) {
        tempYouqianlaiProtocol = item
      }
    })
    ProtocolIdsConfirm1ModalRef.current.init({
      quota: +applyLimit >= 3 ? '100,000' : '50,000',
      productName: detail.productName,
      youqianlaiProtocol: tempYouqianlaiProtocol,
      otherProtocols: temPotherProtocols,
      productId: detail.id,
    })
  }

  // 倒计时
  const [countdownTime, setCountdownTime] = useState<number>(5)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  // 倒计时
  useEffect(() => {
    if (isMobile) {
      if (countdownTime > 0) {
        timer.current = setTimeout(() => {
          setCountdownTime(countdownTime - 1)
        }, 1000)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime])

  return (
    <section className={styles.successPage}>
      <div className={styles.apiToH5Skin}>
        <div className={styles.apiToH5Skin_body}>
          <div className={styles.cardBox}>
            <div className={styles.cardBoxHeader}>
              <img className={styles.cardBoxHeaderBg} src={require('@imgs/information/title-bg.png')} />
              <div className={styles.cardBoxHeaderLeft}>本次贷款服务提供方</div>
              <div className={styles.cardBoxHeaderRight}>
                <img className={styles.cardBoxHeaderRightIcon} src={require('@imgs/icons/crooked-arrows.png')} />
                <div className={styles.cardBoxHeaderRightText}>{`今日申请人数${1001 + detail?.todayMatchNum}人`}</div>
              </div>
            </div>
            <div className={styles.cardBoxContent}>
              <div className={styles.companyBox}>
                <img className={styles.companyBoxLogo} src={require('@imgs/icons/default-company-logo.png')} />
                <div className={styles.companyBoxRight}>
                  <div className={styles.companyBoxProductName}>{ detail.productName }</div>
                  <div className={styles.companyBoxName}>{ detail.productCompany }</div>
                </div>
              </div>
              <div className={styles.productBox}>
                <div className={styles.title}>以下推荐产品由<span className={styles.titleTextActive}>{ detail.productName }</span>提供</div>
                <div className={styles.productListBox}>
                  {
                    thirdProducts?.map((item:any, index:any) => <div key={index} className={styles.productItemBox} onClick={() => checkedThirdProductsTaggle(item, index)}>
                      <img className={styles.productItemIcon} src={item.logo} />
                      <div className={styles.productItemBoxRight}>
                        <div className={styles.productItemName}>
                          <div className={styles.productName}>{ item.productName }</div>
                          <div className={styles.companyName}>{ item.institutionName }</div>
                        </div>
                        <div className={styles.productItemOther}>
                          <div className={styles.productItemOtherItem}>
                            <div className={styles.productItemOtherItemValue}>{ item.maxPrice }</div>
                            <div className={styles.productItemOtherItemLabel}>最高额度(元)</div>
                          </div>
                          <div className={styles.productItemOtherItem}>
                            <div className={styles.productItemOtherItemValue}>{ item.lendingRateString }%</div>
                            <div className={styles.productItemOtherItemLabel}>放款率</div>
                          </div>
                        </div>
                      </div>
                      <div className={classNames(styles.checked, item.checked && styles.active)}></div>
                    </div>)
                  }
                </div>
              </div>
            </div>
          </div>
          <div className={styles.protocolBox}>
            <div className={styles.protocolBoxContent}>
              <div className={styles.protocolBoxTitle}>{ youqianlaiProtocol.name }</div>
              <div dangerouslySetInnerHTML={{ __html: agreementContent }}></div>
            </div>
          </div>
          <div className={styles.specialTips}>
            <div className={styles.specialTipsTag}>特别提示</div>
            <span className={styles.specialTipsContent}>我们会根据您的资质，为您匹配专业的贷款咨询服务机构，请您查阅《{youqianlaiProtocol.name}》《机构相关协议》。</span>
          </div>
        </div>
      </div>
      <div className={styles.footerBox}>
        <div className={styles.footerText}>
          {
            checked ? <>
              <img onClick={checkedTaggle} className={styles.checkedIcon} src={require('@imgs/information/checked.png')} />
            </> : <>
              <div className={classNames(styles.checked)} onClick={checkedTaggle}></div>
            </>
          }
          <div>
            <span onClick={checkedTaggle}>同意并签署</span>
            <span
              className={styles.footerTextRed}
              onClick={() => {
                openOtherView(`/info-audit-agreement?isShowHeader=true&agreementId=${youqianlaiProtocol.id}&title=${youqianlaiProtocol.name}&productId=${detail.id}`)
              }}
            >
          《{youqianlaiProtocol.name}》
            </span>
            {
              otherProtocols.length > 0 && <span
                className={styles.footerTextRed}
                onClick={() => {
                  openProtocolsPopup()
                }}
              >
              《机构相关协议》
              </span>
            }
          </div>
        </div>
        <div className={styles.btnBox}>
          <Button block loading={btnLoading} loadingText="请等待机构申请结果" className={styles.btn} shape="rounded" color="primary" onClick={() => submitApplication(true)}>
            { `立即领取额度${countdownTime ? `（${countdownTime}s）` : ''}`}
          </Button>
        </div>
        <div className={styles.paddingBox}></div>
      </div>

      <Popup
        visible={protocolIdsVisible}
        showCloseButton={true}
        bodyStyle={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          minHeight: '40vh',
        }}
        onMaskClick={() => {
          setProtocolIdsVisible(false)
        }}
        onClose={() => {
          setProtocolIdsVisible(false)
        }}
      >
        <div className={styles.protcolPopup}>
          <div className={styles.popupTitle}>协议列表</div>
          <div className={styles.protocolIdsBox}>
            {
              otherProtocols?.map((item:any) => {
                return (
                  <div
                    className={styles.protocolIdItem}
                    onClick={() => {
                      openOtherView(`/info-audit-agreement?isShowHeader=true&agreementId=${item.id}&title=${item.name}&productId=${detail.id}`)
                    }}
                    key={item.id}
                  >
                    <div>{ `《${item.name}》` }</div>
                    <img className={styles.arrowBlack} src={require('@imgs/information/arrow-black.png')} />
                  </div>
                )
              })
            }
          </div>
        </div>
      </Popup>
      {/* 挽留弹窗 */}
      <ProtocolIdsConfirm1
        ref={ProtocolIdsConfirm1ModalRef}
        onSuccess={(e:any) => handleLoginSuccess(e)}
        openProtocolsPopup={() => openProtocolsPopup()}
        submit={() => handelSubmitData()}/>

      {/* 同意协议弹窗 */}
      <ProtocolIdsConfirm2
        ref={ProtocolIdsConfirm2ModalRef}
        onSuccess={(e:any) => handleLoginSuccess(e)}
        openProtocolsPopup={() => openProtocolsPopup()}
        submit={() => handelSubmitData()}/>
    </section>
  )
}

export default Success